import React, { useEffect, useState } from "react";
import Header from "../components/header/Header";
import Footer from "../components/footer/Footer";
import Aos from "aos";
import { useTheme } from "../context/ThemeContext";
import { useLocation } from "react-router-dom";

const clients = [
  {
    id: 1,
  },
  {
    id: 2,
  },
  {
    id: 3,
  },
  {
    id: 4,
  },
  {
    id: 5,
  },
  {
    id: 6,
  },
];

function OurServices() {
  const location = useLocation();
  const [isOpen, setIsOpen] = useState("1");
  const [select, setSelect] = useState("Film Production");

  const { theme } = useTheme();

  const hashToSelectMap = {
    "#social": "Social Media Marketing",
    "#film": "Film Production",
    "#creative" : "Creative Media Agency"
  };

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
    Aos.init({
      duration: 1000,
    });
  }, []);

  useEffect(() => {
    const hash = location.hash;
    if (hash) {
      const element = document.querySelector(hash);
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }, [location]);

  return (
    <>
      <Header />
      <div
        className={`sm:mt-[104px] mt-[60px] overflow-hidden pt-[94px] ${theme === "light" ? "bg-[#FFFFFF]" : "bg-[#0e0e0e]"
          }`}
      >
        <div
          style={{ fontFamily: "GeneralSans" }}
          className={`our-body ${theme === "light" ? "bg-[#FFFFFF]" : "bg-[#0e0e0e]"
            }`}
        >
          <div data-aos="fade-up">
            <h1
              className={`font-[700] sm:text-[56px] text-[40px] sm:leading-[69.44px] leading-[46px] text-center sm:pb-[28px] pb-[8px] ${theme === "light" ? "text-[#212121]" : "text-[#FFFFFF]"
                }`}
            >
              Our Services
            </h1>
          </div>
          <div data-aos="fade-right">
            <p
              className={`max-w-[828px] m-auto w-full font-[400] pb-[30px] text-[18px] leading-[28.8px] text-center ${theme === "light" ? "text-[#545454]" : "text-[#FFFFFF]"
                }`}
            >
              We’re your creative partner for everything from film production to
              digital marketing, We specialize in everything from engaging
              corporate videos and impactful documentaries and to eye-catching
              commercial ads and product shoots. We connect you with celebrities
              to amplify your brand and craft compelling digital campaigns,
              stunning designs, and unforgettable events.
            </p>
          </div>
        </div>
      </div>
      <img
          src="assets/about/photo8.png"
          className="w-full object-cover sm:hidden block sm:mt-[80px] mt-[-70px] sm:h-auto h-[373px]"
          alt="hero-bg"
        />
      <div className="bg-[#992119] overflow-hidden sm:pt-[100px] pt-[60px] sm:px-[100px] px-[30px] pb-[40px] border-b border-[#FFFFFF]">
        <div className="flex sm:flex-row flex-col sm:gap-[77px] gap-[20px]">
          <div
            style={{ fontFamily: "GeneralSans" }}
            data-aos="fade-right"
            className="flex flex-col max-w-[228px] w-full gap-[15px]"
          >
            <h6 className="font-[700] capitalize text-[24px] leading-[30.12px] text-[#FFFFFF]">
              What we do
            </h6>
            <img
              src="assets/home/vector1.svg"
              className="max-w-[142px] w-full"
              alt=""
            />
          </div>
          <div data-aos="fade-left" className="flex flex-col w-full" id="services">
            <h6
              style={{ fontFamily: "GeneralSans" }}
              className="font-[700] sm:text-[48px] text-[24px] pb-[80px] sm:leading-[59.52px] leading-[30px] text-[#FFFFFF]"
            >
              We offer a comprehensive range of services designed to help your
              business succeed online.
            </h6>
          </div>
        </div>
        <div className="flex flex-wrap gap-[14px]">
          <button
            data-aos="fade-up"
            onClick={() => setSelect("Film Production")}
            className={` transition-all duration-300 py-[10px] px-[25px] rounded-[30px] font-[700] text-[18px] leading-[22.32px]  ${select === "Film Production"
                ? "bg-[#FFFFFF] hover:bg-[#FFFFFF] text-[#992119] hover:text-[#992119]"
                : "bg-[#992119] hover:bg-[#FFFFFF] text-[#FFFFFF] hover:text-[#992119]"
              }`}
            style={{ fontFamily: "GeneralSans" }}
          >
            Film Production
          </button>
          <button
            data-aos="fade-down"
            onClick={() => setSelect("Social Media Marketing")}
            className={` transition-all duration-300 py-[10px] px-[25px] rounded-[30px] font-[700] text-[18px] leading-[22.32px]  ${select === "Social Media Marketing"
                ? "bg-[#FFFFFF] hover:bg-[#FFFFFF] text-[#992119] hover:text-[#992119]"
                : "bg-[#992119] hover:bg-[#FFFFFF] text-[#FFFFFF] hover:text-[#992119]"
              }`}
            style={{ fontFamily: "GeneralSans" }}
          >
            Social Media Marketing
          </button>
          <button
            data-aos="fade-up"
            onClick={() => setSelect("Creative Media Agency")}
            className={` transition-all duration-300 py-[10px] px-[25px] rounded-[30px] font-[700] text-[18px] leading-[22.32px]  ${select === "Creative Media Agency"
                ? "bg-[#FFFFFF] hover:bg-[#FFFFFF] text-[#992119] hover:text-[#992119]"
                : "bg-[#992119] hover:bg-[#FFFFFF] text-[#FFFFFF] hover:text-[#992119]"
              }`}
            style={{ fontFamily: "GeneralSans" }}
          >
            Creative Media Agency
          </button>
        </div>
      </div>
      {select === "Film Production" && (
        <div className="bg-[#992119] overflow-hidden">
          <div className="flex py-[40px] border-b border-[#FFFFFF] items-center sm:px-[100px] px-[20px]">
            <div className="flex flex-col w-full items-start gap-0">
              <div className="flex w-full justify-between items-center">
                <div
                  data-aos="fade-right"
                  style={{ fontFamily: "GeneralSans" }}
                  className="flex sm:items-center items-start gap-[10px]"
                >
                  <h6 className="font-[700] sm:text-[36px] text-[20px] sm:leading-[43.88px] leading-[26px] text-[#FFFFFF]">
                    01.
                  </h6>
                  <h6 className="font-[700] sm:text-[36px] text-[20px] sm:leading-[43.88px] leading-[26px] text-[#FFFFFF]">
                    Corporate Videos
                  </h6>
                </div>
                <button data-aos="fade-left" onClick={() => setIsOpen("1")}>
                  <img
                    src="assets/home/white-right.svg"
                    className={`transition-all duration-300 sm:h-auto h-[40px] ${isOpen === "1" ? "rotate-[120deg]" : "rotate-[-58deg]"
                      }`}
                    alt=""
                  />
                </button>
              </div>
              <div data-aos="fade-left">
                <p
                  style={{ fontFamily: "GeneralSans" }}
                  className={`overflow-hidden transition-all duration-300 pt-[16px] max-w-[911px] w-full font-[400] text-[16px] leading-[20.08px] text-[#FFFFFF] ${isOpen === "1" ? "sm:h-[78px] h-[172px]" : "h-[0px]"
                    }`}
                >
                  <span className="font-[600]">
                    Let’s break the mold of boring corporate videos!
                  </span>{" "}
                  <br />
                  We craft engaging and dynamic videos that reflect your
                  company’s value, culture and vision. From company profiles to
                  every material shoot, we make sure your message shines with
                  style and expertise.
                </p>
              </div>
            </div>
          </div>
          <div className="flex py-[40px] border-b border-[#FFFFFF] items-center sm:px-[100px] px-[20px]">
            <div className="flex flex-col w-full items-start gap-0">
              <div className="flex w-full justify-between items-center">
                <div
                  data-aos="fade-right"
                  style={{ fontFamily: "GeneralSans" }}
                  className="flex sm:items-center items-start gap-[10px]"
                >
                  <h6 className="font-[700] sm:text-[36px] text-[20px] sm:leading-[43.88px] leading-[26px] text-[#FFFFFF]">
                    02.
                  </h6>
                  <h6 className="font-[700] sm:text-[36px] text-[20px] sm:leading-[43.88px] leading-[26px] text-[#FFFFFF]">
                    Commercial AD Shoots
                  </h6>
                </div>
                <button data-aos="fade-left" onClick={() => setIsOpen("2")}>
                  <img
                    src="assets/home/white-right.svg"
                    className={`transition-all duration-300 sm:h-auto h-[40px] ${isOpen === "2" ? "rotate-[120deg]" : "rotate-[-58deg]"
                      }`}
                    alt=""
                  />
                </button>
              </div>
              <div data-aos="fade-right">
                <div
                  style={{ fontFamily: "GeneralSans" }}
                  className={`transition-all overflow-hidden duration-300 ${isOpen === "2" ? "sm:h-[78px] h-[186px]" : "h-[0px]"
                    }`}
                >
                  <p className="pt-[16px] max-w-[911px] w-full font-[400] text-[16px] leading-[20.08px] text-[#FFFFFF]">
                    <span className="font-[600]">
                      Got a product or service you want to-shout about?
                    </span>
                    <br /> Our commercial ad shoots are all about making a
                    splash.
                  </p>
                  <p className="max-w-[911px] w-full font-[400] text-[16px] leading-[20.08px] text-[#FFFFFF]">
                    We blend creativity with strategy to produce ads that not
                    only look stunning but also drive results.
                  </p>
                  <p className="max-w-[911px] w-full font-[400] text-[16px] leading-[20.08px] text-[#FFFFFF]">
                    Whether it’s TV, online , or social media, we’ve got you
                    covered.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="flex py-[40px] border-b border-[#FFFFFF] items-center sm:px-[100px] px-[20px]">
            <div className="flex flex-col w-full items-start gap-0">
              <div className="flex w-full justify-between items-center">
                <div
                  style={{ fontFamily: "GeneralSans" }}
                  data-aos="fade-right"
                  className="flex sm:items-center items-start gap-[10px]"
                >
                  <h6 className="font-[700] sm:text-[36px] text-[20px] sm:leading-[43.88px] leading-[26px] text-[#FFFFFF]">
                    03.
                  </h6>
                  <h6 className="font-[700] sm:text-[36px] text-[20px] sm:leading-[43.88px] leading-[26px] text-[#FFFFFF]">
                    Documentaries
                  </h6>
                </div>
                <button data-aos="fade-left" onClick={() => setIsOpen("3")}>
                  <img
                    src="assets/home/white-right.svg"
                    className={`transition-all duration-300 sm:h-auto h-[40px] ${isOpen === "3" ? "rotate-[120deg]" : "rotate-[-58deg]"
                      }`}
                    alt=""
                  />
                </button>
              </div>
              <div data-aos="fade-right">
                <div
                  style={{ fontFamily: "GeneralSans" }}
                  className={`transition-all overflow-hidden duration-300 ${isOpen === "3" ? "sm:h-[98px] h-[190px]" : "h-[0px]"
                    }`}
                >
                  <p className="pt-[16px] max-w-[911px] w-full font-[400] text-[16px] leading-[20.08px] text-[#FFFFFF]">
                    <span className="font-[600]">
                      At Galiyaara, we’re passionate about storytelling that
                      matters!
                    </span>
                  </p>
                  <p className="max-w-[911px] w-full font-[400] text-[16px] leading-[20.08px] text-[#FFFFFF]">
                    Our team dives deep to uncover the real stories behind the
                    headlines, bringing raw, impactful narratives to life.
                  </p>
                  <p className="max-w-[911px] w-full font-[400] text-[16px] leading-[20.08px] text-[#FFFFFF]">
                    Whether it’s a social issue, a fascinating biography, or an
                    untold story, we capture the essence with authenticity and
                    creativity.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="flex py-[40px] border-b border-[#FFFFFF] items-center sm:px-[100px] px-[20px]">
            <div className="flex flex-col w-full items-start gap-0">
              <div className="flex w-full justify-between items-center">
                <div
                  style={{ fontFamily: "GeneralSans" }}
                  data-aos="fade-right"
                  className="flex sm:items-center items-start gap-[10px]"
                >
                  <h6 className="font-[700] sm:text-[36px] text-[20px] sm:leading-[43.88px] leading-[26px] text-[#FFFFFF]">
                    04.
                  </h6>
                  <h6 className="font-[700] sm:text-[36px] text-[20px] sm:leading-[43.88px] leading-[26px] text-[#FFFFFF]">
                    Testimonial Shoots
                  </h6>
                </div>
                <button data-aos="fade-left" onClick={() => setIsOpen("4")}>
                  <img
                    src="assets/home/white-right.svg"
                    className={`transition-all duration-300 sm:h-auto h-[40px] ${isOpen === "4" ? "rotate-[120deg]" : "rotate-[-58deg]"
                      }`}
                    alt=""
                  />
                </button>
              </div>
              <div data-aos="fade-right">
                <div
                  style={{ fontFamily: "GeneralSans" }}
                  className={`transition-all overflow-hidden duration-300 ${isOpen === "4" ? "sm:h-[108px] h-[156px]" : "h-[0px]"
                    }`}
                >
                  <p className="pt-[16px] max-w-[911px] w-full font-[400] text-[16px] leading-[20.08px] text-[#FFFFFF]">
                    <span className="font-[600]">People trust people!</span>
                  </p>
                  <p className="max-w-[911px] w-full font-[400] text-[16px] leading-[20.08px] text-[#FFFFFF]">
                    That’s why we create testimonial videos that feel genuine
                    and relatable.
                  </p>
                  <p className="max-w-[911px] w-full font-[400] text-[16px] leading-[20.08px] text-[#FFFFFF]">
                    Highlighting real experiences, capturing the honesty and
                    enthusiasm of your satisfied clients to build trust and
                    credibility for your brand.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="flex py-[40px] border-b border-[#FFFFFF] items-center sm:px-[100px] px-[20px]">
            <div className="flex flex-col w-full items-start gap-0">
              <div className="flex w-full justify-between items-center">
                <div
                  style={{ fontFamily: "GeneralSans" }}
                  data-aos="fade-right"
                  className="flex sm:items-center items-start gap-[10px]"
                >
                  <h6 className="font-[700] sm:text-[36px] text-[20px] sm:leading-[43.88px] leading-[26px] text-[#FFFFFF]">
                    05.
                  </h6>
                  <h6 className="font-[700] sm:text-[36px] text-[20px] sm:leading-[43.88px] leading-[26px] text-[#FFFFFF]">
                    Product Shoot
                  </h6>
                </div>
                <button data-aos="fade-left" onClick={() => setIsOpen("5")}>
                  <img
                    src="assets/home/white-right.svg"
                    className={`transition-all duration-300 sm:h-auto h-[40px] ${isOpen === "5" ? "rotate-[120deg]" : "rotate-[-58deg]"
                      }`}
                    alt=""
                  />
                </button>
              </div>
              <div data-aos="fade-right">
                <div
                  style={{ fontFamily: "GeneralSans" }}
                  className={`transition-all overflow-hidden duration-300 ${isOpen === "5" ? "sm:h-[84px] h-[140px]" : "h-[0px]"
                    }`}
                >
                  <p className="pt-[16px] max-w-[911px] w-full font-[400] text-[16px] leading-[20.08px] text-[#FFFFFF]">
                    <span className="font-[600]">
                      Your products deserve the spotlight!
                    </span>
                  </p>
                  <p className="max-w-[911px] w-full font-[400] text-[16px] leading-[20.08px] text-[#FFFFFF]">
                    Our product shoots focus on highlighting the unique features
                    and quality of your products.
                  </p>
                  <p className="max-w-[911px] w-full font-[400] text-[16px] leading-[20.08px] text-[#FFFFFF]">
                    With keen attention to detail and artistic flair, we create
                    visuals that make your products irresistible.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="flex py-[40px] border-b border-[#FFFFFF] items-center sm:px-[100px] px-[20px]">
            <div className="flex flex-col w-full items-start gap-0">
              <div className="flex w-full justify-between items-center">
                <div
                  style={{ fontFamily: "GeneralSans" }}
                  data-aos="fade-right"
                  className="flex sm:items-center items-start gap-[10px]"
                >
                  <h6 className="font-[700] sm:text-[36px] text-[20px] sm:leading-[43.88px] leading-[26px] text-[#FFFFFF]">
                    06.
                  </h6>
                  <h6 className="font-[700] sm:text-[36px] text-[20px] sm:leading-[43.88px] leading-[26px] text-[#FFFFFF]">
                    Product Videos
                  </h6>
                </div>
                <button data-aos="fade-left" onClick={() => setIsOpen("6")}>
                  <img
                    src="assets/home/white-right.svg"
                    className={`transition-all duration-300 sm:h-auto h-[40px] ${isOpen === "6" ? "rotate-[120deg]" : "rotate-[-58deg]"
                      }`}
                    alt=""
                  />
                </button>
              </div>
              <div data-aos="fade-right">
                <div
                  style={{ fontFamily: "GeneralSans" }}
                  className={`transition-all overflow-hidden duration-300 ${isOpen === "6" ? "sm:h-[88px] h-[160px]" : "h-[0px]"
                    }`}
                >
                  <p className="pt-[16px] max-w-[911px] w-full font-[400] text-[16px] leading-[20.08px] text-[#FFFFFF]">
                    <span className="font-[600]">
                      A picture is worth a thousand words, but a video!
                    </span>
                    It’s the whole story.
                  </p>
                  <p className="max-w-[911px] w-full font-[400] text-[16px] leading-[20.08px] text-[#FFFFFF]">
                    Our product videos showcase your products in action,
                    demonstrating their benefits and uses in a way that static
                    images just can’t match. We make sure your audience sees why
                    they need what you’re offering.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="flex py-[40px] border-b border-[#FFFFFF] items-center sm:px-[100px] px-[20px]">
            <div className="flex flex-col w-full items-start gap-0">
              <div className="flex w-full justify-between items-center">
                <div
                  style={{ fontFamily: "GeneralSans" }}
                  data-aos="fade-right"
                  className="flex sm:items-center items-start gap-[10px]"
                >
                  <h6 className="font-[700] sm:text-[36px] text-[20px] sm:leading-[43.88px] leading-[26px] text-[#FFFFFF]">
                    07.
                  </h6>
                  <h6 className="font-[700] sm:text-[36px] text-[20px] sm:leading-[43.88px] leading-[26px] text-[#FFFFFF]">
                    Line Production
                  </h6>
                </div>
                <button data-aos="fade-left" onClick={() => setIsOpen("7")}>
                  <img
                    src="assets/home/white-right.svg"
                    className={`transition-all duration-300 sm:h-auto h-[40px] ${isOpen === "7" ? "rotate-[120deg]" : "rotate-[-58deg]"
                      }`}
                    alt=""
                  />
                </button>
              </div>
              <div data-aos="fade-right">
                <div
                  style={{ fontFamily: "GeneralSans" }}
                  className={`transition-all overflow-hidden duration-300 ${isOpen === "7" ? "sm:h-[58px] h-[138px]" : "h-[0px]"
                    }`}
                >
                  <p className="pt-[16px] max-w-[911px] w-full font-[400] text-[16px] leading-[20.08px] text-[#FFFFFF]">
                    <span className="font-[600]">
                      Behind every great film is a rock-solid line production
                      team. We take care of everything, from finding the perfect
                      location to managing the crew, ensuring your shoot goes
                      off without a hitch. You focus on the creative vision, and
                      we'll handle the rest!
                    </span>
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="flex py-[40px] border-b border-[#FFFFFF] items-center sm:px-[100px] px-[20px]">
            <div className="flex flex-col w-full items-start gap-0">
              <div className="flex w-full justify-between items-center">
                <div
                  style={{ fontFamily: "GeneralSans" }}
                  data-aos="fade-right"
                  className="flex sm:items-center items-start gap-[10px]"
                >
                  <h6 className="font-[700] sm:text-[36px] text-[20px] sm:leading-[43.88px] leading-[26px] text-[#FFFFFF]">
                    08.
                  </h6>
                  <h6 className="font-[700] sm:text-[36px] text-[20px] sm:leading-[43.88px] leading-[26px] text-[#FFFFFF]">
                    Conceptual Videos
                  </h6>
                </div>
                <button data-aos="fade-left" onClick={() => setIsOpen("8")}>
                  <img
                    src="assets/home/white-right.svg"
                    className={`transition-all duration-300 sm:h-auto h-[40px] ${isOpen === "8" ? "rotate-[120deg]" : "rotate-[-58deg]"
                      }`}
                    alt=""
                  />
                </button>
              </div>
              <div data-aos="fade-right">
                <div
                  style={{ fontFamily: "GeneralSans" }}
                  className={`transition-all overflow-hidden duration-300 ${isOpen === "8" ? "sm:h-[108px] h-[170px]" : "h-[0px]"
                    }`}
                >
                  <p className="pt-[16px] max-w-[911px] w-full font-[400] text-[16px] leading-[20.08px] text-[#FFFFFF]">
                    <span className="font-[600]">Have a wild idea?</span>
                  </p>
                  <p className="max-w-[911px] w-full font-[400] text-[16px] leading-[20.08px] text-[#FFFFFF]">
                    We have a wilder execution!
                  </p>
                  <p className="max-w-[911px] w-full font-[400] text-[16px] leading-[20.08px] text-[#FFFFFF]">
                    Our conceptual video team thrives on bringing abstract and
                    innovative concepts to life. Whether it’s a surreal visual
                    journey or a thought-provoking narrative, we push the
                    boundaries to create something truly unique.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="flex py-[40px] border-b border-[#FFFFFF] items-center sm:px-[100px] px-[20px]">
            <div className="flex flex-col w-full items-start gap-0">
              <div className="flex w-full justify-between items-center">
                <div
                  style={{ fontFamily: "GeneralSans" }}
                  data-aos="fade-right"
                  className="flex sm:items-center items-start gap-[10px]"
                >
                  <h6 className="font-[700] sm:text-[36px] text-[20px] sm:leading-[43.88px] leading-[26px] text-[#FFFFFF]">
                    09.
                  </h6>
                  <h6 className="font-[700] sm:text-[36px] text-[20px] sm:leading-[43.88px] leading-[26px] text-[#FFFFFF]">
                    Celebrity Endorsement
                  </h6>
                </div>
                <button data-aos="fade-left" onClick={() => setIsOpen("9")}>
                  <img
                    src="assets/home/white-right.svg"
                    className={`transition-all duration-300 sm:h-auto h-[40px] ${isOpen === "9" ? "rotate-[120deg]" : "rotate-[-58deg]"
                      }`}
                    alt=""
                  />
                </button>
              </div>
              <div data-aos="fade-right">
                <div
                  style={{ fontFamily: "GeneralSans" }}
                  className={`transition-all overflow-hidden duration-300 ${isOpen === "9" ? "sm:h-[108px] h-[170px]" : "h-[0px]"
                    }`}
                >
                  <p className="pt-[16px] max-w-[911px] w-full font-[400] text-[16px] leading-[20.08px] text-[#FFFFFF]">
                    <span className="font-[600]">
                      Want to add star power to your brand?
                    </span>
                  </p>
                  <p className="max-w-[911px] w-full font-[400] text-[16px] leading-[20.08px] text-[#FFFFFF]">
                    Our celebrity endorsement services connect you with the
                    right personalities to amplify your message. We handle
                    everything from negotiations to campaign execution, ensuring
                    that your brand gets the spotlight it deserves with the
                    perfect celebrity match.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {select === "Social Media Marketing" && (
        <div className="bg-[#992119] overflow-hidden">
          <div className="flex py-[40px] border-b border-[#FFFFFF] items-center sm:px-[100px] px-[20px]">
            <div className="flex flex-col w-full items-start gap-0">
              <div className="flex w-full justify-between items-center">
                <div
                  style={{ fontFamily: "GeneralSans" }}
                  data-aos="fade-right"
                  className="flex sm:items-center items-start gap-[10px]"
                >
                  <h6 className="font-[700] sm:text-[36px] text-[20px] sm:leading-[43.88px] leading-[26px] text-[#FFFFFF]">
                    01.
                  </h6>
                  <h6 className="font-[700] sm:text-[36px] text-[20px] sm:leading-[43.88px] leading-[26px] text-[#FFFFFF]">
                    Digital Campaign
                  </h6>
                </div>
                <button data-aos="fade-left" onClick={() => setIsOpen("1")}>
                  <img
                    src="assets/home/white-right.svg"
                    className={`transition-all duration-300 sm:h-auto h-[40px] ${isOpen === "1" ? "rotate-[120deg]" : "rotate-[-58deg]"
                      }`}
                    alt=""
                  />
                </button>
              </div>
              <div data-aos="fade-right">
                <p
                  style={{ fontFamily: "GeneralSans" }}
                  className={`overflow-hidden transition-all duration-300 pt-[16px] max-w-[911px] w-full font-[400] text-[16px] leading-[20.08px] text-[#FFFFFF] ${isOpen === "1" ? "sm:h-[58px] h-[138px]" : "h-[0px]"
                    }`}
                >
                  <span className="font-[600]">
                    Ready to make waves online?
                  </span>
                  <br /> Our digital campaigns are designed to capture attention
                  and drive engagement. From creative concepts to targeted
                  strategies, we craft campaigns that resonate with your
                  audience and deliver real results.
                </p>
              </div>
            </div>
          </div>
          <div className="flex py-[40px] border-b border-[#FFFFFF] items-center sm:px-[100px] px-[20px]">
            <div className="flex flex-col w-full items-start gap-0">
              <div className="flex w-full justify-between items-center">
                <div
                  style={{ fontFamily: "GeneralSans" }}
                  data-aos="fade-right"
                  className="flex sm:items-center items-start gap-[10px]"
                >
                  <h6 className="font-[700] sm:text-[36px] text-[20px] sm:leading-[43.88px] leading-[26px] text-[#FFFFFF]">
                    02.
                  </h6>
                  <h6 className="font-[700] sm:text-[36px] text-[20px] sm:leading-[43.88px] leading-[26px] text-[#FFFFFF]">
                    SWOT Analysis
                  </h6>
                </div>
                <button data-aos="fade-left" onClick={() => setIsOpen("2")}>
                  <img
                    src="assets/home/white-right.svg"
                    className={`transition-all duration-300 sm:h-auto h-[40px] ${isOpen === "2" ? "rotate-[120deg]" : "rotate-[-58deg]"
                      }`}
                    alt=""
                  />
                </button>
              </div>
              <div data-aos="fade-right">
                <div
                  style={{ fontFamily: "GeneralSans" }}
                  className={`transition-all overflow-hidden duration-300 ${isOpen === "2" ? "sm:h-[94px] h-[168px]" : "h-[0px]"
                    }`}
                >
                  <p className="pt-[16px] max-w-[911px] w-full font-[400] text-[16px] leading-[20.08px] text-[#FFFFFF]">
                    <span className="font-[600]">
                      Understanding your strengths, weaknesses, opportunities, and
                      threats is key to any successful strategy.
                    </span>
                  </p>
                  <p className="pt-[16px] max-w-[911px] w-full font-[400] text-[16px] leading-[20.08px] text-[#FFFFFF]">
                    Our SWOT analysis dives deep into your brand and market,
                    providing actionable insights that help you stay ahead of
                    the competition.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="flex py-[40px] border-b border-[#FFFFFF] items-center sm:px-[100px] px-[20px]">
            <div className="flex flex-col w-full items-start gap-0">
              <div className="flex w-full justify-between items-center">
                <div
                  style={{ fontFamily: "GeneralSans" }}
                  data-aos="fade-right"
                  className="flex sm:items-center items-start gap-[10px]"
                >
                  <h6 className="font-[700] sm:text-[36px] text-[20px] sm:leading-[43.88px] leading-[26px] text-[#FFFFFF]">
                    03.
                  </h6>
                  <h6 className="font-[700] sm:text-[36px] text-[20px] sm:leading-[43.88px] leading-[26px] text-[#FFFFFF]">
                    Posters
                  </h6>
                </div>
                <button data-aos="fade-left" onClick={() => setIsOpen("3")}>
                  <img
                    src="assets/home/white-right.svg"
                    className={`transition-all duration-300 sm:h-auto h-[40px] ${isOpen === "3" ? "rotate-[120deg]" : "rotate-[-58deg]"
                      }`}
                    alt=""
                  />
                </button>
              </div>
              <div data-aos="fade-right">
                <div
                  style={{ fontFamily: "GeneralSans" }}
                  className={`transition-all overflow-hidden duration-300 ${isOpen === "3" ? "sm:h-[58px] h-[117px]" : "h-[0px]"
                    }`}
                >
                  <p className="pt-[16px] max-w-[911px] w-full font-[400] text-[16px] leading-[20.08px] text-[#FFFFFF]">
                    Eye-catching and impactful, our posters are designed to grab
                    attention and convey your message instantly. Whether it’s
                    for an event, a product launch, or a promotional campaign,
                    we create designs that stand out in the crowd.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="flex py-[40px] border-b border-[#FFFFFF] items-center sm:px-[100px] px-[20px]">
            <div className="flex flex-col w-full items-start gap-0">
              <div className="flex w-full justify-between items-center">
                <div
                  style={{ fontFamily: "GeneralSans" }}
                  data-aos="fade-right"
                  className="flex sm:items-center items-start gap-[10px]"
                >
                  <h6 className="font-[700] sm:text-[36px] text-[20px] sm:leading-[43.88px] leading-[26px] text-[#FFFFFF]">
                    04.
                  </h6>
                  <h6 className="font-[700] sm:text-[36px] text-[20px] sm:leading-[43.88px] leading-[26px] text-[#FFFFFF]">
                    Motion Posters
                  </h6>
                </div>
                <button data-aos="fade-left" onClick={() => setIsOpen("4")}>
                  <img
                    src="assets/home/white-right.svg"
                    className={`transition-all duration-300 sm:h-auto h-[40px] ${isOpen === "4" ? "rotate-[120deg]" : "rotate-[-58deg]"
                      }`}
                    alt=""
                  />
                </button>
              </div>
              <div data-aos="fade-right">
                <div
                  style={{ fontFamily: "GeneralSans" }}
                  className={`transition-all overflow-hidden duration-300 ${isOpen === "4" ? "sm:h-[88px] h-[166px]" : "h-[0px]"
                    }`}
                >
                  <p className="pt-[16px] max-w-[911px] w-full font-[400] text-[16px] leading-[20.08px] text-[#FFFFFF]">
                    <span className="font-[600]">
                      Take your visual storytelling to the next level with motion
                      posters.
                    </span>
                  </p>
                  <p className="max-w-[911px] w-full font-[400] text-[16px] leading-[20.08px] text-[#FFFFFF]">
                    These dynamic visuals add movement to your message, making
                    it more engaging and memorable. Perfect for social media and
                    digital displays, motion posters bring your ideas to life.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="flex py-[40px] border-b border-[#FFFFFF] items-center sm:px-[100px] px-[20px]">
            <div className="flex flex-col w-full items-start gap-0">
              <div className="flex w-full justify-between items-center">
                <div
                  style={{ fontFamily: "GeneralSans" }}
                  data-aos="fade-right"
                  className="flex sm:items-center items-start gap-[10px]"
                >
                  <h6 className="font-[700] sm:text-[36px] text-[20px] sm:leading-[43.88px] leading-[26px] text-[#FFFFFF]">
                    05.
                  </h6>
                  <h6 className="font-[700] sm:text-[36px] text-[20px] sm:leading-[43.88px] leading-[26px] text-[#FFFFFF]">
                    Video Production
                  </h6>
                </div>
                <button data-aos="fade-left" onClick={() => setIsOpen("5")}>
                  <img
                    src="assets/home/white-right.svg"
                    className={`transition-all duration-300 sm:h-auto h-[40px] ${isOpen === "5" ? "rotate-[120deg]" : "rotate-[-58deg]"
                      }`}
                    alt=""
                  />
                </button>
              </div>
              <div data-aos="fade-right">
                <div
                  style={{ fontFamily: "GeneralSans" }}
                  className={`transition-all overflow-hidden duration-300 ${isOpen === "5" ? "sm:h-[88px] h-[166px]" : "h-[0px]"
                    }`}
                >
                  <p className="pt-[16px] max-w-[911px] w-full font-[400] text-[16px] leading-[20.08px] text-[#FFFFFF]">
                    <span className="font-[600]">
                      Videos are the heart of digital content.
                    </span>
                  </p>
                  <p className="max-w-[911px] w-full font-[400] text-[16px] leading-[20.08px] text-[#FFFFFF]">
                    Our video production services cover everything from concept
                    to final cut, ensuring high-quality, compelling videos that
                    captivate your audience. Whether it’s for YouTube,
                    Instagram, or your website, we make videos that people want
                    to watch.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="flex py-[40px] border-b border-[#FFFFFF] items-center sm:px-[100px] px-[20px]">
            <div className="flex flex-col w-full items-start gap-0">
              <div className="flex w-full justify-between items-center">
                <div
                  style={{ fontFamily: "GeneralSans" }}
                  data-aos="fade-right"
                  className="flex sm:items-center items-start gap-[10px]"
                >
                  <h6 className="font-[700] sm:text-[36px] text-[20px] sm:leading-[43.88px] leading-[26px] text-[#FFFFFF]">
                    06.
                  </h6>
                  <h6 className="font-[700] sm:text-[36px] text-[20px] sm:leading-[43.88px] leading-[26px] text-[#FFFFFF]">
                    Event Management
                  </h6>
                </div>
                <button data-aos="fade-left" onClick={() => setIsOpen("6")}>
                  <img
                    src="assets/home/white-right.svg"
                    className={`transition-all duration-300 sm:h-auto h-[40px] ${isOpen === "6" ? "rotate-[120deg]" : "rotate-[-58deg]"
                      }`}
                    alt=""
                  />
                </button>
              </div>
              <div data-aos="fade-right">
                <div
                  style={{ fontFamily: "GeneralSans" }}
                  className={`transition-all overflow-hidden duration-300 ${isOpen === "6" ? "sm:h-[88px] h-[177px]" : "h-[0px]"
                    }`}
                >
                  <p className="pt-[16px] max-w-[911px] w-full font-[400] text-[16px] leading-[20.08px] text-[#FFFFFF]">
                    <span className="font-[600]">
                      From planning to execution, we make your events
                      unforgettable.
                    </span>
                  </p>
                  <p className="max-w-[911px] w-full font-[400] text-[16px] leading-[20.08px] text-[#FFFFFF]">
                    Whether it’s a corporate gathering, a product launch, or a
                    grand celebration, our event management team handles all the
                    details. We create experiences that leave a lasting
                    impression, so you can focus on enjoying the moment.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {select === "Creative Media Agency" && (
        <div className="bg-[#992119] overflow-hidden">
          <div className="flex py-[40px] border-b border-[#FFFFFF] items-center sm:px-[100px] px-[20px]">
            <div className="flex flex-col w-full items-start gap-0">
              <div className="flex w-full justify-between items-center">
                <div
                  style={{ fontFamily: "GeneralSans" }}
                  data-aos="fade-right"
                  className="flex sm:items-center items-start gap-[10px]"
                >
                  <h6 className="font-[700] sm:text-[36px] text-[20px] sm:leading-[43.88px] leading-[26px] text-[#FFFFFF]">
                    01.
                  </h6>
                  <h6 className="font-[700] sm:text-[36px] text-[20px] sm:leading-[43.88px] leading-[26px] text-[#FFFFFF]">
                    Designing & Development
                  </h6>
                </div>
                <button data-aos="fade-left" onClick={() => setIsOpen("1")}>
                  <img
                    src="assets/home/white-right.svg"
                    className={`transition-all duration-300 sm:h-auto h-[40px] ${isOpen === "1" ? "rotate-[120deg]" : "rotate-[-58deg]"
                      }`}
                    alt=""
                  />
                </button>
              </div>
              <div data-aos="fade-right">
                <p
                  style={{ fontFamily: "GeneralSans" }}
                  className={`overflow-hidden transition-all duration-300 pt-[16px] max-w-[911px] w-full font-[400] text-[16px] leading-[20.08px] text-[#FFFFFF] ${isOpen === "1" ? "sm:h-[58px] h-[148px]" : "h-[0px]"
                    }`}
                >
                  Need a fresh look? Our design and development team brings
                  creativity and functionality together. From stunning websites
                  to eye-catching graphics, we create digital and print designs
                  that are not only beautiful but also effective.
                </p>
              </div>
            </div>
          </div>
          <div className="flex py-[40px] border-b border-[#FFFFFF] items-center sm:px-[100px] px-[20px]">
            <div className="flex flex-col w-full items-start gap-0">
              <div className="flex w-full justify-between items-center">
                <div
                  style={{ fontFamily: "GeneralSans" }}
                  data-aos="fade-right"
                  className="flex sm:items-center items-start gap-[10px]"
                >
                  <h6 className="font-[700] sm:text-[36px] text-[20px] sm:leading-[43.88px] leading-[26px] text-[#FFFFFF]">
                    02.
                  </h6>
                  <h6 className="font-[700] sm:text-[36px] text-[20px] sm:leading-[43.88px] leading-[26px] text-[#FFFFFF]">
                    Product Packaging
                  </h6>
                </div>
                <button data-aos="fade-left" onClick={() => setIsOpen("2")}>
                  <img
                    src="assets/home/white-right.svg"
                    className={`transition-all duration-300 sm:h-auto h-[40px] ${isOpen === "2" ? "rotate-[120deg]" : "rotate-[-58deg]"
                      }`}
                    alt=""
                  />
                </button>
              </div>
              <div data-aos="fade-right">
                <div
                  style={{ fontFamily: "GeneralSans" }}
                  className={`transition-all overflow-hidden duration-300 ${isOpen === "2" ? "sm:h-[94px] h-[170px]" : "h-[0px]"
                    }`}
                >
                  <p className="pt-[16px] max-w-[911px] w-full font-[400] text-[16px] leading-[20.08px] text-[#FFFFFF]">
                    Great products deserve great packaging. We create designs
                    for your product packaging that not only protect your
                    products but also tell your brand’s story.
                  </p>
                  <p className="pt-[16px] max-w-[911px] w-full font-[400] text-[16px] leading-[20.08px] text-[#FFFFFF]">
                    With a keen eye for detail and a focus on aesthetics, we
                    create packaging that stands out on the shelves.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="flex py-[40px] border-b border-[#FFFFFF] items-center sm:px-[100px] px-[20px]">
            <div className="flex flex-col w-full items-start gap-0">
              <div className="flex w-full justify-between items-center">
                <div
                  style={{ fontFamily: "GeneralSans" }}
                  data-aos="fade-right"
                  className="flex sm:items-center items-start gap-[10px]"
                >
                  <h6 className="font-[700] sm:text-[36px] text-[20px] sm:leading-[43.88px] leading-[26px] text-[#FFFFFF]">
                    03.
                  </h6>
                  <h6 className="font-[700] sm:text-[36px] text-[20px] sm:leading-[43.88px] leading-[26px] text-[#FFFFFF]">
                    Shop Branding/In-Shop Branding
                  </h6>
                </div>
                <button data-aos="fade-left" onClick={() => setIsOpen("3")}>
                  <img
                    src="assets/home/white-right.svg"
                    className={`transition-all duration-300 sm:h-auto h-[40px] ${isOpen === "3" ? "rotate-[120deg]" : "rotate-[-58deg]"
                      }`}
                    alt=""
                  />
                </button>
              </div>
              <div data-aos="fade-right">
                <div
                  style={{ fontFamily: "GeneralSans" }}
                  className={`transition-all overflow-hidden duration-300 ${isOpen === "3" ? "sm:h-[58px] h-[148px]" : "h-[0px]"
                    }`}
                >
                  <p className="pt-[16px] max-w-[911px] w-full font-[400] text-[16px] leading-[20.08px] text-[#FFFFFF]">
                    Transform your retail space with our shop branding services.
                    We create immersive in-shop experiences that reflect your
                    brand’s identity and attract customers. From window displays
                    to interior design, we ensure your shop makes a lasting
                    impression.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="flex py-[40px] border-b border-[#FFFFFF] items-center sm:px-[100px] px-[20px]">
            <div className="flex flex-col w-full items-start gap-0">
              <div className="flex w-full justify-between items-center">
                <div
                  style={{ fontFamily: "GeneralSans" }}
                  data-aos="fade-right"
                  className="flex sm:items-center items-start gap-[10px]"
                >
                  <h6 className="font-[700] sm:text-[36px] text-[20px] sm:leading-[43.88px] leading-[26px] text-[#FFFFFF]">
                    04.
                  </h6>
                  <h6 className="font-[700] sm:text-[36px] text-[20px] sm:leading-[43.88px] leading-[26px] text-[#FFFFFF]">
                    Brochure/Leaflet/Pamphlet
                  </h6>
                </div>
                <button data-aos="fade-left" onClick={() => setIsOpen("4")}>
                  <img
                    src="assets/home/white-right.svg"
                    className={`transition-all duration-300 sm:h-auto h-[40px] ${isOpen === "4" ? "rotate-[120deg]" : "rotate-[-58deg]"
                      }`}
                    alt=""
                  />
                </button>
              </div>
              <div data-aos="fade-right">
                <div
                  style={{ fontFamily: "GeneralSans" }}
                  className={`transition-all overflow-hidden duration-300 ${isOpen === "4" ? "sm:h-[58px] h-[148px]" : "h-[0px]"
                    }`}
                >
                  <p className="pt-[16px] max-w-[911px] w-full font-[400] text-[16px] leading-[20.08px] text-[#FFFFFF]">
                    Printed materials are still powerful marketing tools. We
                    design brochures, leaflets, and pamphlets that are visually
                    appealing and packed with information. Perfect for events,
                    mailouts, or in-store handouts, our print designs help you
                    communicate effectively.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="flex py-[40px] border-b border-[#FFFFFF] items-center sm:px-[100px] px-[20px]">
            <div className="flex flex-col w-full items-start gap-0">
              <div className="flex w-full justify-between items-center">
                <div
                  style={{ fontFamily: "GeneralSans" }}
                  data-aos="fade-right"
                  className="flex sm:items-center items-start gap-[10px]"
                >
                  <h6 className="font-[700] sm:text-[36px] text-[20px] sm:leading-[43.88px] leading-[26px] text-[#FFFFFF]">
                    05.
                  </h6>
                  <h6 className="font-[700] sm:text-[36px] text-[20px] sm:leading-[43.88px] leading-[26px] text-[#FFFFFF]">
                    Many More
                  </h6>
                </div>
                <button data-aos="fade-left" onClick={() => setIsOpen("5")}>
                  <img
                    src="assets/home/white-right.svg"
                    className={`transition-all duration-300 sm:h-auto h-[40px] ${isOpen === "5" ? "rotate-[120deg]" : "rotate-[-58deg]"
                      }`}
                    alt=""
                  />
                </button>
              </div>
              <div data-aos="fade-right">
                <div
                  style={{ fontFamily: "GeneralSans" }}
                  className={`transition-all overflow-hidden duration-300 ${isOpen === "5" ? "sm:h-[58px] h-[148px]" : "h-[0px]"
                    }`}
                >
                  <p className="pt-[16px] max-w-[911px] w-full font-[400] text-[16px] leading-[20.08px] text-[#FFFFFF]">
                    From innovative marketing strategies to bespoke content
                    creation, our diverse range of services is designed to meet
                    all your branding and advertising needs. Let’s create
                    something amazing together.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {/* <div
        className={`sm:pt-[75px] overflow-hidden pt-[40px] sm:px-[100px] px-[20px] pb-[50px] border-b ${
          theme === "light"
            ? "border-[#000000] bg-[#FFFFFF]"
            : "border-[#FFFFFF] bg-[#000000]"
        }`}
      >
        <div data-aos="fade-right">
          <h6 style={{ fontFamily: "GeneralSans" }}
            className={`font-[700] pb-[12px] text-[40px] leading-[49.6px] ${
              theme === "light" ? "text-[#000000]" : "text-[#FFFFFF]"
            }`}
          >
            Clients Testimonials
          </h6>
        </div>
        <div data-aos="fade-left">
          <p style={{ fontFamily: "GeneralSans" }}
            className={`font-[400] text-[18px] leading-[28.8px] max-w-[780px] w-full ${
              theme === "light" ? "text-[#616161]" : "text-[#FFFFFF]"
            }`}
          >
            Discover firsthand what our clients say about our impact and
            effectiveness. Their detailed testimonials reflect the success of
            our creative solutions in meeting diverse business goals.
          </p>
        </div>
      </div> */}
      <div
        className={`sm:px-[100px] overflow-hidden px-[20px] sm:pt-[50px] pt-[40px] sm:pb-[98px] pb-[40px] ${theme === "light" ? "bg-[#FFFFFF]" : "bg-[#000000]"
          }`}
      >
        {/* <div className="grid sm:grid-cols-3 grid-cols-1 sm:gap-[30px] gap-[14px]">
          {clients?.map((e, i) => {
            return (
              <>
                <div className="bg-[#F6EDFF] overflow-hidden rounded-[30px] p-[30px]">
                  <h6 
                    data-aos="fade-down"
                    className="font-[700] text-[24px] leading-[29.76px] text-[#8A5BB8] pb-[12px]"
                    style={{ fontFamily: "GeneralSans" }}
                  >
                    Great works!
                  </h6>
                  <p style={{ fontFamily: "GeneralSans" }}
                    data-aos="fade-right"
                    className="font-[400] text-[18px] leading-[28.8px] text-[#4D4D4D] pb-[33px]"
                  >
                    "Galiyaara consistently delivers great works that exceed our
                    expectations. Their team's creativity and technical skills
                    are top-notch. They truly understand our brand and vision,
                    resulting in amazing websites that drive results."
                  </p>
                  <div className="flex gap-[16px] items-center">
                    <img
                      data-aos="fade-right"
                      src="assets/home/photo8.svg"
                      alt=""
                    />
                    <div style={{ fontFamily: "GeneralSans" }} data-aos="fade-left">
                      <h6
                        className="font-[500] text-[20px] leading-[24.8px] text-[#212121]"
                      >
                        Chaitanya Goyal
                      </h6>
                      <p className="font-[400] text-[16px] leading-[25.6px] text-[#616161]">
                        Founder, Varlyq
                      </p>
                    </div>
                  </div>
                </div>
              </>
            );
          })}
        </div> */}
        <div className="bg-[#992119] sm:mt-[100px] mt-[20px] sm:px-[178px] px-[20px] pt-[76px] pb-[92px] rounded-[30px]">
          <h6
            data-aos="fade-down"
            className="text-center sm:text-[40px] text-[30px] font-[700] sm:leading-[49.6px] leading-[38px] text-[#FFFFFF]"
            style={{ fontFamily: "GeneralSans" }}
          >
            Why you should choose Galiyaara?
          </h6>
          <div className="grid lg:grid-cols-3 grid-cols-1 gap-[20px] justify-center sm:pt-[98px] pt-[40px]">
            <div
              data-aos="fade-down"
              className="bg-transparent border-[3px] border-[rgb(255,255,255)] rounded-full h-[270px] w-[270px] flex items-center flex-col justify-center mx-auto"
            >
              <h6
                style={{ fontFamily: 'GeneralSans' }}
                className="font-[700] text-[20px] leading-[24.8px] text-center text-[#FFFFFF]"
              >
                Expertise
              </h6>
              <p
                style={{ fontFamily: 'GeneralSans' }}
                className="font-[400] text-[16px] leading-[20.08px] pt-[16px] text-center text-[#E0E0E0] max-w-[196px] w-full"
              >
                Our team of experts has years of experience in web design, development, and digital marketing
              </p>
            </div>
            <div
              data-aos="fade-up"
              className="bg-transparent border-[3px] border-[#FFFFFF] rounded-full h-[270px] w-[270px] flex items-center flex-col justify-center mx-auto"
            >
              <h6
                style={{ fontFamily: 'GeneralSans' }}
                className="font-[700] text-[20px] leading-[24.8px] text-center text-[#FFFFFF]"
              >
                Collaborative
              </h6>
              <p
                style={{ fontFamily: 'GeneralSans' }}
                className="font-[400] text-[16px] leading-[20.08px] pt-[16px] text-center text-[#E0E0E0] max-w-[196px] w-full"
              >
                We believe in working closely with our clients to understand their needs and goals.
              </p>
            </div>
            <div
              data-aos="fade-down"
              className="bg-transparent border-[3px] border-[#FFFFFF] rounded-full h-[270px] w-[270px] flex items-center flex-col justify-center mx-auto"
            >
              <h6
                style={{ fontFamily: 'GeneralSans' }}
                className="font-[700] text-[20px] leading-[24.8px] text-center text-[#FFFFFF]"
              >
                Results
              </h6>
              <p
                style={{ fontFamily: 'GeneralSans' }}
                className="font-[400] text-[16px] leading-[20.08px] pt-[16px] text-center text-[#E0E0E0] max-w-[196px] w-full"
              >
                We are committed to delivering measurable results that help our clients achieve their business objectives.
              </p>
            </div>
          </div>

        </div>
      </div>
      <Footer />
    </>
  );
}

export default OurServices;
