import React, { useEffect, useState } from "react";
import Header from "../components/header/Header";
import Footer from "../components/footer/Footer";
import Aos from "aos";
import { Link } from "react-router-dom";
import { useTheme } from "../context/ThemeContext";
import { toast } from "react-toastify";

function Portfolios() {
  let backendURL = process.env.REACT_APP_backendURL;
  const [loading, setLoading] = useState(false);
  const [categories, setCategories] = useState([]);
  const [portfolio, setPortfolio] = useState([]);
  const [btn, setBtn] = useState("Film Production");
  const { theme } = useTheme();

  useEffect(() => {
    getCategory();
    getPortfolio();
  }, []);

  const getCategory = async () => {
    try {
      setLoading(true);
      const response = await fetch(`${backendURL}/category`, {
        method: "GET",
      });
      const data = await response.json();
      if (response.ok) {
        setCategories(data?.data);
      } else {
        if (data.message === "Invalid token") {
          alert("Invalid token. Please Login and try again");
        } else {
        }
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.error("Error fetching categories:", error);
    }
  };
  const getPortfolio = async () => {
    try {
      setLoading(true);
      const response = await fetch(`${backendURL}/portfolio`, {
        method: "GET",
      });
      const data = await response.json();
      if (response.ok) {
        setPortfolio(data?.data);
        // console.log(data?.data);
      } else {
        if (data.message === "Invalid token") {
          alert("Invalid token. Please Login and try again");
        } else {
        }
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.error("Error fetching categories:", error);
    }
  };

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });

    Aos.init({
      duration: 1000,
    });
  }, []);

  return (
    <>
      <Header />
      <div
        className={`sm:mt-[105px] mt-[60px] ${
          theme === "light" ? "bg-[#ffffff]" : "bg-[#171717]"
        }`}
      >
        <div className="pt-[48px] flex flex-col items-center">
          <div data-aos="fade-up">
            <p
              style={{ fontFamily: "GeneralSans" }}
              className={`font-[700] text-[18px] leading-[28.8px] text-center pb-[12px] ${
                theme === "light" ? "text-[#545454]" : "text-[#FFFFFF]"
              }`}
            >
              Portfolio
            </p>
          </div>
          <div data-aos="fade-down">
            <h6
              style={{ fontFamily: "GeneralSans" }}
              className={`max-w-[644px] pb-[20px] m-auto w-full font-[700] sm:text-[56px] text-[30px] sm:leading-[69.44px] leading-[36px] text-center ${
                theme === "light" ? "text-[#000000]" : "text-[#FFFFFF]"
              }`}
            >
              We create beautiful, practical works
            </h6>
          </div>
          {theme === "light" ? (
            <>
              <div className="flex sm:flex-row flex-col justify-center items-center gap-[14px] pb-[67px]">
                {categories?.map((category) => (
                  <>
                    <button
                      style={{ fontFamily: "GeneralSans" }}
                      onClick={() => setBtn(category?.name)}
                      className={`font-[500] text-[18px] leading-[22.32px]  py-[10px] px-[25px] rounded-[30px] transition-all duration-300 ${
                        btn === category?.name
                          ? "bg-[#992119] text-[#FFFFFF]"
                          : "bg-[#9921190D] text-[#616161]"
                      }`}
                    >
                      {category?.name}
                    </button>
                  </>
                ))}
              </div>
            </>
          ) : (
            <div className="flex sm:flex-row flex-col justify-center items-center gap-[14px] pb-[67px]">
              {categories?.map((category) => (
                <>
                  <button
                    style={{ fontFamily: "GeneralSans" }}
                    onClick={() => setBtn(category?.name)}
                    className={`font-[500] text-[18px] leading-[22.32px]  py-[10px] px-[25px] rounded-[30px] transition-all duration-300 ${
                      btn === category?.name
                        ? "bg-[#992119] text-[#FFFFFF]"
                        : "bg-[#DBDBDB0D] text-[#FFFFFF]"
                    }`}
                  >
                    {category?.name}
                  </button>
                </>
              ))}
            </div>
          )}
          <div className="flex flex-col sm:pb-[100px] pb-[20px] gap-[30px]">
            {portfolio?.map((port) => (
              <div className="relative port-card">
                <img
                  src={port?.banner ? port?.banner : "assets/port/photo1.png"}
                  alt=""
                  className="min-w-[50vw]"
                />
                <div
                  style={{ fontFamily: "GeneralSans" }}
                  className="bg-[#992119] border max-w-[517px] port-body w-full flex items-center justify-between border-[#E84037] absolute right-[30px] bottom-[22px] rounded-[16px] px-[26px] py-[22px]"
                >
                  <h6
                    data-aos="fade-right"
                    className="font-[800] italic text-[24px] leading-[26px] text-[#FFFFFF]"
                  >
                    {port?.name}
                  </h6>
                  <Link to={`/our-work/${port?._id}`}>
                    <button
                      data-aos="fade-left"
                      className="border border-[#FFFFFF] pl-[16px] rounded-[30px] gap-[18px] flex items-center justify-center font-[Mulish] font-[600] text-[18px] leading-[28.8px] text-[#FFFFFF] py-[3px] pr-[4px]"
                    >
                      View <img src="assets/port/arrow.svg" alt="" />
                    </button>
                  </Link>
                </div>
              </div>
            ))}
            {/* <div className="relative port-card">
              <img src="assets/port/photo1.png" alt="" />
              <div
                style={{ fontFamily: "GeneralSans" }}
                className="bg-[#992119] border max-w-[517px] port-body w-full flex items-center justify-between border-[#E84037] absolute right-[30px] bottom-[22px] rounded-[16px] px-[26px] py-[22px]"
              >
                <h6
                  data-aos="fade-right"
                  className="font-[800] italic text-[24px] leading-[26px] text-[#FFFFFF]"
                >
                  Digital Campaigns
                </h6>
                <Link to="/our-work">
                  <button
                    data-aos="fade-left"
                    className="border border-[#FFFFFF] pl-[16px] rounded-[30px] gap-[18px] flex items-center justify-center font-[Mulish] font-[600] text-[18px] leading-[28.8px] text-[#FFFFFF] py-[3px] pr-[4px]"
                  >
                    View <img src="assets/port/arrow.svg" alt="" />
                  </button>
                </Link>
              </div>
            </div>
            <div className="relative port-card">
              <img src="assets/port/photo2.png" alt="" />
              <div
                style={{ fontFamily: "GeneralSans" }}
                className="bg-[#992119] border max-w-[517px] port-body w-full flex items-center justify-between border-[#E84037] absolute right-[30px] bottom-[22px] rounded-[16px] px-[26px] py-[22px]"
              >
                <h6
                  data-aos="fade-right"
                  className="font-[800] italic text-[24px] leading-[26px] text-[#FFFFFF]"
                >
                  Digital Campaigns
                </h6>
                <Link to="/our-work">
                  <button
                    data-aos="fade-left"
                    className="border border-[#FFFFFF] pl-[16px] rounded-[30px] gap-[18px] flex items-center justify-center font-[Mulish] font-[600] text-[18px] leading-[28.8px] text-[#FFFFFF] py-[3px] pr-[4px]"
                  >
                    View <img src="assets/port/arrow.svg" alt="" />
                  </button>
                </Link>
              </div>
            </div>
            <div className="relative port-card">
              <img src="assets/port/photo3.png" alt="" />
              <div
                style={{ fontFamily: "GeneralSans" }}
                className="bg-[#992119] border max-w-[517px] port-body w-full flex items-center justify-between border-[#E84037] absolute right-[30px] bottom-[22px] rounded-[16px] px-[26px] py-[22px]"
              >
                <h6
                  data-aos="fade-right"
                  className="font-[800] italic text-[24px] leading-[26px] text-[#FFFFFF]"
                >
                  Digital Campaigns
                </h6>
                <Link to="/our-work">
                  <button
                    data-aos="fade-left"
                    className="border border-[#FFFFFF] pl-[16px] rounded-[30px] gap-[18px] flex items-center justify-center font-[Mulish] font-[600] text-[18px] leading-[28.8px] text-[#FFFFFF] py-[3px] pr-[4px]"
                  >
                    View <img src="assets/port/arrow.svg" alt="" />
                  </button>
                </Link>
              </div>
            </div>
            <div className="relative port-card">
              <img src="assets/port/photo4.png" alt="" />
              <div
                style={{ fontFamily: "GeneralSans" }}
                className="bg-[#992119] border max-w-[517px] port-body w-full flex items-center justify-between border-[#E84037] absolute right-[30px] bottom-[22px] rounded-[16px] px-[26px] py-[22px]"
              >
                <h6
                  data-aos="fade-right"
                  className="font-[800] italic text-[24px] leading-[26px] text-[#FFFFFF]"
                >
                  Digital Campaigns
                </h6>
                <Link to="/our-work">
                  <button
                    data-aos="fade-left"
                    className="border border-[#FFFFFF] pl-[16px] rounded-[30px] gap-[18px] flex items-center justify-center font-[Mulish] font-[600] text-[18px] leading-[28.8px] text-[#FFFFFF] py-[3px] pr-[4px]"
                  >
                    View <img src="assets/port/arrow.svg" alt="" />
                  </button>
                </Link>
              </div>
            </div> */}
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default Portfolios;
