import React, { useEffect, useState } from "react";
import Footer from "../components/footer/Footer";
import Header from "../components/header/Header";
import { useNavigate, useParams } from "react-router-dom";
import Aos from "aos";
import { useTheme } from "../context/ThemeContext";

function PortfoioPage() {
  const { id } = useParams();
  const navigate = useNavigate();
  let backendURL = process.env.REACT_APP_backendURL;
  const [loading, setLoading] = useState(false);
  const [portfolio, setPortfolio] = useState("");
  const { theme } = useTheme();

  useEffect(() => {
    getCategory(id);
  }, []);

  const getCategory = async (id) => {
    try {
      setLoading(true);
      const response = await fetch(`${backendURL}/portfolio/${id}`, {
        method: "GET",
      });
      const data = await response.json();
      if (response.ok) {
        setPortfolio(data?.data);
        console.log(data.data);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.error("Error fetching categories:", error);
    }
  };

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });

    Aos.init({
      duration: 1000,
    });
  }, []);

  return (
    <>
      <Header />
      <div
        className={`sm:mt-[104px] mt-[60px] ${
          theme === "light" ? "bg-[#ffffff]" : "bg-[#171717]"
        }`}
      >
        <div className="sm:pt-[70px] pt-[30px] sm:px-[72px] px-[20px] sm:pb-[100px] pb-[40px] flex justify-between items-center">
          <div className="sm:flex hidden flex-1"></div>
          <div className="flex flex-1 sm:justify-center justify-start">
            <h6
              style={{ fontFamily: "GeneralSans" }}
              className={`font-[700] italic sm:text-[48px] text-[20px] leading-[26px] ${
                theme === "light" ? "text-[#000000]" : "text-[#FFFFFF]"
              }`}
            >
              {portfolio?.name}
            </h6>
          </div>
          <div className="flex flex-1 justify-end">
            <button onClick={() => navigate(-1)}>
              <img
                src="/assets/port/close.svg"
                className="sm:h-auto h-[40px]"
                alt=""
              />
            </button>
          </div>
        </div>
      </div>
      <div
        className={`sm:px-[40px] px-[20px] ${
          theme === "light" ? "bg-[#ffffff]" : "bg-[#171717]"
        }`}
      >
        <div className="grid sm:grid-cols-2 grid-cols-1 sm:gap-[16px] gap-0 gap-y-[16px]">
          <div className="sm:col-span-2 col-span-1">
            <div className="flex w-full items-center justify-center">
              <img data-aos="fade-up" src={portfolio?.banner} alt="" />
            </div>
          </div>
          {renderImages(portfolio?.images || [])}
        </div>
      </div>
      <Footer />
    </>
  );
}

export default PortfoioPage;
const renderImages = (images) => {
  return images.map((image, index) => {
    if (index === 0 || index === 1) {
      // For the first two images (similar layout)
      return (
        <div key={index} className="flex w-full items-center justify-center">
          <img data-aos="fade-up" src={image} alt={image.alt || "Image"} />
        </div>
      );
    }

    // For the third image and beyond (complex layout)
    return (
      <div
        key={index}
        className="sm:col-span-2 sm:py-[180px] py-[40px] col-span-1"
      >
        <div className="flex w-full items-center justify-center">
          <img data-aos="fade-up" src={image} alt={image.alt || "Image"} />
        </div>
      </div>
    );
  });
};
