import React, { useEffect, useState } from "react";
import Header from "../components/header/Header";
import Footer from "../components/footer/Footer";
import { Link } from "react-router-dom";
import Aos from "aos";
import { useTheme } from "../context/ThemeContext";

function OurWork() {
  let backendURL = process.env.REACT_APP_backendURL;
  const [loading, setLoading] = useState(false);
  const [categories, setCategories] = useState([]);
  const [portfolio, setPortfolio] = useState([]);
  const { theme } = useTheme();
  const [btn, setBtn] = useState("Film Production");

  useEffect(() => {
    getCategory();
    getPortfolio();
  }, []);
  const getCategory = async () => {
    try {
      setLoading(true);
      const response = await fetch(`${backendURL}/category`, {
        method: "GET",
      });
      const data = await response.json();
      if (response.ok) {
        setCategories(data?.data);
      } else {
        if (data.message === "Invalid token") {
          alert("Invalid token. Please Login and try again");
        } else {
          // toast.error("Error fetching categories", { autoClose: 1000 });
        }
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.error("Error fetching categories:", error);
      // toast.error("Error fetching categories", { autoClose: 1000 });
    }
  };
  const getPortfolio = async () => {
    try {
      setLoading(true);
      const response = await fetch(`${backendURL}/portfolio`, {
        method: "GET",
      });
      const data = await response.json();
      if (response.ok) {
        setPortfolio(data?.data);
        // console.log(data?.data);
      } else {
        if (data.message === "Invalid token") {
          alert("Invalid token. Please Login and try again");
        } else {
          // toast.error("Error fetching categories", { autoClose: 1000 });
        }
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.error("Error fetching categories:", error);
      // toast.error("Error fetching categories", { autoClose: 1000 });
    }
  };

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });

    Aos.init({
      duration: 1000,
    });
  }, []);

  return (
    <>
      <Header />
      <div
        className={`sm:mt-[105px] mt-[60px] ${
          theme === "light" ? "bg-[#FFFFFF]" : "bg-[#000000]"
        }`}
      >
        <div className="pt-[48px] flex flex-col items-center">
          <div data-aos="fade-up">
            <p
              style={{ fontFamily: "GeneralSans" }}
              className={`font-[700] text-[18px] leading-[28.8px] text-center pb-[12px] ${
                theme === "light" ? "text-[#545454]" : "text-[#FFFFFF]"
              }`}
            >
              Our Work
            </p>
          </div>
          <div data-aos="fade-right">
            <h6
              style={{ fontFamily: "GeneralSans" }}
              className={`max-w-[644px] pb-[20px] m-auto w-full font-[700] sm:text-[56px] text-[30px] sm:leading-[69.44px] leading-[36px] text-center ${
                theme === "light" ? "text-[#000000]" : "text-[#FFFFFF]"
              }`}
            >
              We create beautiful, practical works
            </h6>
          </div>
          {theme === "light" ? (
            <div className="flex sm:flex-row flex-col justify-center items-center gap-[14px] pb-[67px]">
              {categories?.map((category) => (
                <>
                  <button
                    style={{ fontFamily: "GeneralSans" }}
                    onClick={() => setBtn(category?.name)}
                    className={`font-[500] text-[18px] leading-[22.32px]  py-[10px] px-[25px] rounded-[30px] transition-all duration-300 ${
                      btn === category?.name
                        ? "bg-[#992119] text-[#FFFFFF]"
                        : "bg-[#9921190D] text-[#616161]"
                    }`}
                  >
                    {category?.name}
                  </button>
                </>
              ))}
            </div>
          ) : (
            <div className="flex sm:flex-row flex-col justify-center items-center gap-[14px] pb-[67px]">
              {categories?.map((category) => (
                <>
                  <button
                    style={{ fontFamily: "GeneralSans" }}
                    onClick={() => setBtn(category?.name)}
                    className={`font-[500] text-[18px] leading-[22.32px]  py-[10px] px-[25px] rounded-[30px] transition-all duration-300 ${
                      btn === category?.name
                        ? "bg-[#992119] text-[#FFFFFF]"
                        : "bg-[#DBDBDB0D] text-[#FFFFFF]"
                    }`}
                  >
                    {category?.name}
                  </button>
                </>
              ))}
            </div>
          )}
          <div className="grid sm:grid-cols-2 grid-cols-1 sm:px-[100px] px-[20px] sm:pb-[200px] pb-[70px] gap-[46px]">
            {portfolio?.map((port) => (
              <div className="relative">
                <Link to={`/our-work/${port?._id}`}>
                  <img src={port?.banner} alt="" />
                  <div className="card-overlay">
                    <div
                      style={{ fontFamily: "GeneralSans" }}
                      className="bg-transparent flex items-center justify-between w-full absolute left-[50%] translate-x-[-50%] bottom-[28px] rounded-[16px] px-[37px]"
                    >
                      <h6 className="font-[800] italic text-[24px] leading-[26px] text-[#FFFFFF]">
                        {port?.name}
                      </h6>
                      <button className="border bg-[#FFFFFF] border-[#FFFFFF] pl-[16px] rounded-[30px] gap-[18px] flex items-center justify-center font-[600] text-[18px] leading-[28.8px] text-[#E84037] py-[3px] pr-[4px]">
                        View <img src="assets/home/right-arrow.svg" alt="" />
                      </button>
                    </div>
                  </div>
                </Link>
              </div>
            ))}
            <div className="relative">
              <Link to="/our-work/hunter">
                <img src="assets/port/photo6.png" alt="" />
                <div className="card-overlay">
                  <div
                    style={{ fontFamily: "GeneralSans" }}
                    className="bg-transparent flex items-center justify-between w-full absolute left-[50%] translate-x-[-50%] bottom-[28px] rounded-[16px] px-[37px]"
                  >
                    <h6 className="font-[800] italic text-[24px] leading-[26px] text-[#FFFFFF]">
                      Hunter Beer
                    </h6>
                    <button className="border bg-[#FFFFFF] border-[#FFFFFF] pl-[16px] rounded-[30px] gap-[18px] flex items-center justify-center font-[600] text-[18px] leading-[28.8px] text-[#E84037] py-[3px] pr-[4px]">
                      View <img src="assets/home/right-arrow.svg" alt="" />
                    </button>
                  </div>
                </div>
              </Link>
            </div>
            <div className="relative">
              <Link to="/our-work/hunter">
                <img src="assets/port/photo7.png" alt="" />
                <div className="card-overlay">
                  <div
                    style={{ fontFamily: "GeneralSans" }}
                    className="bg-transparent flex items-center justify-between w-full absolute left-[50%] translate-x-[-50%] bottom-[28px] rounded-[16px] px-[37px]"
                  >
                    <h6 className="font-[800] italic text-[24px] leading-[26px] text-[#FFFFFF]">
                      Mushroom AD
                    </h6>
                    <button className="border bg-[#FFFFFF] border-[#FFFFFF] pl-[16px] rounded-[30px] gap-[18px] flex items-center justify-center font-[600] text-[18px] leading-[28.8px] text-[#E84037] py-[3px] pr-[4px]">
                      View <img src="assets/home/right-arrow.svg" alt="" />
                    </button>
                  </div>
                </div>
              </Link>
            </div>
            <div className="relative">
              <Link to="/our-work/hunter">
                <img src="assets/port/photo8.png" alt="" />
                <div className="card-overlay">
                  <div
                    style={{ fontFamily: "GeneralSans" }}
                    className="bg-transparent flex items-center justify-between w-full absolute left-[50%] translate-x-[-50%] bottom-[28px] rounded-[16px] px-[37px]"
                  >
                    <h6 className="font-[800] italic text-[24px] leading-[26px] text-[#FFFFFF]">
                      Shreedhi
                    </h6>
                    <button className="border bg-[#FFFFFF] border-[#FFFFFF] pl-[16px] rounded-[30px] gap-[18px] flex items-center justify-center font-[600] text-[18px] leading-[28.8px] text-[#E84037] py-[3px] pr-[4px]">
                      View <img src="assets/home/right-arrow.svg" alt="" />
                    </button>
                  </div>
                </div>
              </Link>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default OurWork;
