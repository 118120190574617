import React, { useEffect } from "react";
import Header from "../components/header/Header";
import Footer from "../components/footer/Footer";
import Aos from "aos";
import { useTheme } from "../context/ThemeContext";

const ourSlider = [
  {
    id: 1,
    img: "assets/home/photo5.png",
  },
  {
    id: 2,
    img: "assets/home/photo6.png",
  },
  {
    id: 3,
    img: "assets/home/photo5.png",
  },
  {
    id: 4,
    img: "assets/home/photo6.png",
  },
  {
    id: 5,
    img: "assets/home/photo5.png",
  },
  {
    id: 6,
    img: "assets/home/photo6.png",
  },
  {
    id: 7,
    img: "assets/home/photo5.png",
  },
  {
    id: 8,
    img: "assets/home/photo6.png",
  },
  {
    id: 9,
    img: "assets/home/photo5.png",
  },
  {
    id: 10,
    img: "assets/home/photo6.png",
  },
  {
    id: 11,
    img: "assets/home/photo5.png",
  },
  {
    id: 12,
    img: "assets/home/photo6.png",
  },
  {
    id: 13,
    img: "assets/home/photo5.png",
  },
  {
    id: 14,
    img: "assets/home/photo6.png",
  },
  {
    id: 15,
    img: "assets/home/photo5.png",
  },
  {
    id: 16,
    img: "assets/home/photo6.png",
  },
];

function AboutUs() {
  const { theme } = useTheme();

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
    Aos.init({
      duration: 1000,
    });
  }, []);

  return (
    <>
      <Header />
      <div
        className={`relative sm:px-0 px-[30px] sm:pt-[60px] overflow-hidden pt-[30px] sm:mt-[105px] pb-[20px] mt-[60px] sm:mb-[80px] ${
          theme === "light" ? "bg-[#FFFFFF]" : "bg-[#0e0e0e]"
        }`}
      >
        <div
          style={{ fontFamily: "GeneralSans" }}
          className="grid sm:grid-cols-1 grid-cols-1 gap-[20px] sm:px-[100px] px-[0px] items-center"
        >
          <div data-aos="fade-right">
            <h1
              className={`pb-[14px] w-full text-center max-w-[654px] mx-auto font-[600] sm:text-[42px] text-[30px] sm:leading-[54px] leading-[36px] ${
                theme === "light" ? "text-[#212121]" : "text-[#FFFFFF]"
              }`}
            >
              Get to Know Our Creative Team and Our Journey
            </h1>
          </div>
          <div data-aos="fade-left">
            <p
              style={{ fontFamily: "GeneralSans" }}
              className={`max-w-[874px] m-auto font-[400] sm:text-[18px] text-[14px] text-center sm:leading-[27px] leading-[20px] ${
                theme === "light" ? "text-[#545454]" : "text-[#FFFFFF]"
              }`}
            >
              At Galiyaara, we’re all about bringing your stories to life. <br /> We’re
              not just about production, we’re about passion, creativity, and
              making your vision come alive
            </p>
          </div>
        </div>
      </div>
        <img
          src="assets/about/photo7.png"
          className="w-full object-cover  sm:h-auto h-[373px]"
          alt="hero-bg"
        />
      <div className="bg-[#992119] overflow-hidden sm:pt-[140px] pt-[40px] sm:pb-[80px] pb-[40px]">
        <div className="flex sm:flex-row flex-col sm:px-[100px] px-[30px] sm:pb-[65px] pb-[30px] sm:gap-[77px] gap-[20px]">
          <div
            data-aos="fade-right"
            style={{ fontFamily: "GeneralSans" }}
            className="flex flex-col max-w-[228px] w-full gap-[15px]"
          >
            <h6 className="font-[700] text-[24px] leading-[30.12px] text-[#FFFFFF]">
              Our Story
            </h6>
            <img
              src="assets/home/vector1.svg"
              className="max-w-[111px] w-full"
              alt=""
            />
          </div>
          <div data-aos="fade-left" className="flex flex-col w-full">
            <p
              style={{ fontFamily: "GeneralSans" }}
              className="font-[700] sm:text-[48px] text-[24px] leading-[30px] sm:leading-[59.52px] text-[#FFFFFF]"
            >
              Founded in 2015, Galiyaara quickly became a leader in Film
              Production and Social Media Marketing, Creative Media Agency.
            </p>
          </div>
        </div>
        <div className="flex sm:flex-row sm:h-[335px] h-auto flex-col gap-[20px] justify-center items-center px-[18px]">
          <img
            data-aos="fade-right"
            className="w-full"
            src="assets/about/photo2.png"
            alt=""
          />
          <img
            data-aos="fade-left"
            className="w-full h-[335px] object-cover rounded-[8px]"
            src="assets/about/photo3.png"
            alt=""
          />
        </div>
      </div>
      {/* <div className="time-section overflow-hidden">
        <div className="time-box">
          <div className="grid sm:grid-cols-4 grid-cols-1 sm:gap-0 gap-[30px] pt-[200px]">
            <div
              style={{ fontFamily: "GeneralSans" }}
              data-aos="fade-up"
              className="flex flex-col items-center gap-[11px]"
            >
              <img src="assets/home/icon1.svg" alt="" />
              <h6 className="font-[700] text-[32px] leading-[39.68px] text-[#FFFFFF]">
                200+
              </h6>
              <p className="max-w-[157px] font-[400] text-[18px] leading-[22.59px] text-center text-[#FFFFFFCC]">
                Raised By Clients
              </p>
            </div>
            <div
              data-aos="fade-up"
              style={{ fontFamily: "GeneralSans" }}
              className="flex flex-col items-center gap-[11px]"
            >
              <img src="assets/home/icon2.svg" alt="" />
              <h6 className="font-[700] text-[32px] leading-[39.68px] text-[#FFFFFF]">
                10+
              </h6>
              <p className="max-w-[157px] font-[400] text-[18px] leading-[22.59px] text-center text-[#FFFFFFCC]">
                Years Of Experience
              </p>
            </div>
            <div
              data-aos="fade-up"
              style={{ fontFamily: "GeneralSans" }}
              className="flex flex-col items-center gap-[11px]"
            >
              <img src="assets/home/icon3.svg" alt="" />
              <h6 className="font-[700] text-[32px] leading-[39.68px] text-[#FFFFFF]">
              1000+
              </h6>
              <p className="max-w-[157px] font-[400] text-[18px] leading-[22.59px] text-center text-[#FFFFFFCC]">
                Projects Delivery Done
              </p>
            </div>
            <div
              style={{ fontFamily: "GeneralSans" }}
              data-aos="fade-up"
              className="flex flex-col items-center gap-[11px]"
            >
              <img src="assets/home/icon4.svg" alt="" />
              <h6 className="font-[700] text-[32px] leading-[39.68px] text-[#FFFFFF]">
                4.8
              </h6>
              <p className="max-w-[157px] font-[400] text-[18px] leading-[22.59px] text-center text-[#FFFFFFCC]">
                Rating On Trust Pilot
              </p>
            </div>
          </div>
        </div>
      </div> */}
      {/* <div className="bg-[#FFFFFF] overflow-hidden sm:pt-[78px] pt-[30px]">
        <div className="flex sm:px-[100px] px-[20px] sm:flex-row flex-col sm:gap-0 gap-[20px] pb-[34px] border-b border-[#212121] justify-between sm:items-center items-start">
          <h6
            style={{ fontFamily: "GeneralSans" }}
            data-aos="fade-right"
            className="font-[600] sm:text-[40px] text-[20px] sm:leading-[48.76px] leading-[26px]"
          >
            Our Prestigious Clients
          </h6>
          <button
            data-aos="fade-left"
            className="border py-[4px] pr-[4px] pl-[24px] border-[#000000] rounded-[30px] font-[600] flex items-center gap-[50px] text-[22px] leading-[35.2px]"
          >
            View All
            <img src="assets/home/right-arrow.svg" alt="" />
          </button>
        </div>
        <div className="sm:px-[100px] px-[20px] sm:pb-[150px] pb-[50px] pt-[53px]">
          <div className="grid sm:grid-cols-5 items-center grid-cols-4 sm:gap-y-[20px] gap-y-[14px] sm:gap-[75px] gap-[30px]">
            <div data-aos="fade-up">
              <img src="assets/logo/1.jpg" alt="" />
            </div>
            <div data-aos="fade-down">
              <img src="assets/logo/2.png" alt="" />
            </div>
            <div data-aos="fade-up">
              <img src="assets/logo/3.png" alt="" />
            </div>
            <div data-aos="fade-down">
              <div className="flex justify-center">
                <img
                  src="assets/logo/4.png"
                  className="h-[30px] sm:h-[100px]"
                  alt=""
                />
              </div>
            </div>
            <div data-aos="fade-up">
              <div className="flex justify-center">
                <img
                  src="assets/logo/5.jpg"
                  className="h-[30px] sm:h-[100px]"
                  alt=""
                />
              </div>
            </div>
            <div data-aos="fade-down">
              <img src="assets/logo/6.png" alt="" />
            </div>
            <div data-aos="fade-up">
              <div className="flex justify-center">
                <img
                  src="assets/logo/7.png"
                  className="h-[30px] sm:h-[100px]"
                  alt=""
                />
              </div>
            </div>
            <div data-aos="fade-down">
              <img src="assets/logo/8.png" alt="" />
            </div>
            <div data-aos="fade-up">
              <img src="assets/logo/9.png" alt="" />
            </div>
            <div data-aos="fade-down">
              <div className="flex justify-center">
                <img
                  src="assets/logo/10.jpg"
                  className="h-[30px] sm:h-[100px]"
                  alt=""
                />
              </div>
            </div>
            <div data-aos="fade-up">
              <div className="flex justify-center">
                <img
                  src="assets/logo/11.jpg"
                  className="h-[30px] sm:h-[100px]"
                  alt=""
                />
              </div>
            </div>
            <div data-aos="fade-down">
              <div className="flex justify-center">
                <img
                  src="assets/logo/12.png"
                  className="h-[30px] sm:h-[100px]"
                  alt=""
                />
              </div>
            </div>
            <div data-aos="fade-up">
              <div className="flex justify-center">
                <img
                  src="assets/logo/13.JPG"
                  className="h-[30px] sm:h-[100px]"
                  alt=""
                />
              </div>
            </div>
            <div data-aos="fade-down">
              <div className="flex justify-center">
                <img
                  src="assets/logo/14.png"
                  className="h-[30px] sm:h-[100px]"
                  alt=""
                />
              </div>
            </div>
            <div data-aos="fade-up">
              <div className="flex justify-center">
                <img
                  src="assets/logo/15.jpg"
                  className="h-[30px] sm:h-[100px]"
                  alt=""
                />
              </div>
            </div>
            <div data-aos="fade-down">
              <div className="flex justify-center">
                <img
                  src="assets/logo/16.png"
                  className="h-[30px] sm:h-[100px]"
                  alt=""
                />
              </div>
            </div>
            <div data-aos="fade-up">
              <img src="assets/logo/17.png" alt="" />
            </div>
            <div data-aos="fade-down">
              <div className="flex justify-center">
                <img
                  src="assets/logo/19.png"
                  className="h-[30px] sm:h-[100px]"
                  alt=""
                />
              </div>
            </div>
            <div data-aos="fade-up">
              <img src="assets/logo/20.png" alt="" />
            </div>
            <div data-aos="fade-down">
              <img src="assets/logo/21.png" alt="" />
            </div>
          </div>
        </div>
      </div> */}
      <div
        className={`overflow-hidden ${
          theme === "light" ? "bg-[#FFFFFF]" : "bg-[#0e0e0e]"
        } sm:pt-[64px] pt-[40px]`}
      >
        <div className="px-[18px] sm:pb-[87px] pb-[40px]">
          <div className="bg-[#992119] rounded-[30px] relative sm:pb-[119px] pb-[40px] sm:pl-[88px] pl-[20px] sm:pr-[120px] pr-[20px]">
            <div className="sm:flex hidden gap-[8px] absolute left-[170px] -z-0">
              <img data-aos="fade-down" src="assets/about/wrap1.svg" alt="" />
              <img data-aos="fade-up" src="assets/about/wrap1.svg" alt="" />
              <img data-aos="fade-down" src="assets/about/wrap1.svg" alt="" />
            </div>
            <div className="flex sm:flex-row flex-col items-center sm:gap-[113px] gap-[20px]">
              <div className="relative w-full max-w-[282px] z-10 pt-[77px]">
                <img src="assets/home/item11.jpeg" className="border-[6px] border-[#FFFFFF] rounded-full" alt="" />
                <div
                  style={{ fontFamily: "GeneralSans" }}
                  className="bg-[#000000] absolute bottom-[-60px] w-full py-[14px] rounded-[30px] border-[2px] border-[#ffffff] max-w-[282px] flex flex-col items-center"
                >
                  <h6
                    data-aos="fade-right"
                    className="font-[600] text-[22px] leading-[35.2px] text-[#FFFFFF]"
                  >
                    Sumit Dubey
                  </h6>
                  <p
                    data-aos="fade-left"
                    className="font-[600] text-[16px] leading-[25.6px] text-[#FFFFFFCC]"
                  >
                    - Founder, Galiyaara Films
                  </p>
                </div>
              </div>
              <p
                data-aos="fade-left"
                style={{ fontFamily: "GeneralSans" }}
                className="max-w-[802px] sm:pt-[123px] pt-[80px] w-full font-[500] text-[18px] leading-[28.8px] text-[#FFFFFF]"
              >
                Extremely Creative, Innovative, Energetic, Self Motivated person
                with more than 10 years of successful business leadership in
                film production . Creator of many Corporate Films, Television
                Commercial and Documentaries etc . He knows what works and has a
                fundamental understanding of film production. His talents
                identify and implement strong brand creative thought that match
                the aspirations of customer and clients . He is motivated as an
                artist, which means simply that he will deliver a concept that
                marks you out and accords with your true values. He helps you to
                implement your ideas and creative thoughts to make a beautifully
                created film, a corporate film, Ad Film or Documentary.
              </p>
            </div>
          </div>
        </div>
        {/* <div data-aos="fade-up">
          <h6
            className={`font-[700] sm:px-[84px] px-[20px] sm:pb-[72px] pb-[40px] text-[20px] sm:text-[40px] leading-[26px] sm:leading-[49.6px] text-center ${
              theme === "light" ? "text-[#0e0e0e]" : "text-[#FFFFFF]"
            }`}
            style={{ fontFamily: "GeneralSans" }}
          >
            Our team include experienced Creative Director, Media Planners,
            Designers, Content Writers and Video Editors along with certified
            social media managers and we are committed to the success of our
            clients.
          </h6>
        </div>
        <div className="overflow-hidden sm:pb-[104px] pb-[40px]">
          <div className="grid sm:grid-cols-4 grid-cols-1 sm:gap-[25px] gap-[14px] sm:px-[112px] px-[20px]">
            {ourSlider?.map((e, i) => {
              return (
                <>
                  <div
                    key={i}
                    className="w-full m-auto bg-[#E4E4E4] relative flex px-[15px] items-center justify-center h-[383px]"
                  >
                    <img
                      data-aos="fade-up"
                      src={e?.img}
                      className="h-full object-cover"
                      alt=""
                    />
                    <div
                      style={{ fontFamily: "GeneralSans" }}
                      className="absolute bottom-[39px] py-[18px] px-[24px] bg-[#FFFFFF]"
                    >
                      <h6
                        data-aos="fade-right"
                        className="font-[600] text-[22px] pb-[2px] leading-[35.2px] text-[#212121]"
                      >
                        Chaitanya Goyal
                      </h6>
                      <p
                        data-aos="fade-left"
                        className="font-[600] text-[16px] leading-[25.6px] text-[#545454]"
                      >
                        - Video Editor, Galiyaara Films
                      </p>
                    </div>
                  </div>
                </>
              );
            })}
          </div>
        </div> */}
      </div>
      <Footer />
    </>
  );
}

export default AboutUs;
