import React, { useState } from "react";
import "./header.css";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useTheme } from "../../context/ThemeContext";

function Header() {
  const navigate = useNavigate();
  const { theme, toggleTheme } = useTheme();
  const path = useLocation();
  // console.log(path?.pathname);
  const [dropdown, setDropdown] = useState(false);
  const [modal, setModal] = useState(false);
  function handleDropdown() {
    setDropdown(!dropdown);
  }
  function handleModal() {
    setModal(!modal);
  }

  const handleButtonClick = () => {
    if (window.gtag) {
      window.gtag("event", "custom_event", {
        event_category: "User",
        event_label: "Submit Form",
        value: "Clicked Submit Button",
      });
    }
  };

  return (
    <>
      <div
        className={` flex w-full justify-between sm:h-[105px] h-[60px] z-50 fixed top-0 items-center sm:px-[90px] px-[14px] py-[16px] ${
          theme === "light" ? "bg-[#F6F6F6]" : "bg-[#1C1C1C]"
        }`}
      >
        <div
          className="overlay"
          onClick={handleDropdown}
          style={dropdown ? { right: 0 } : { right: "100%" }}
        ></div>
        <Link to="/" className="relative flex sm:top-[32px] top-[10px]">
          <img
            src="/assets/logo1.svg"
            className="sm:h-auto h-[60px] object-cover"
            alt=""
          />
        </Link>
        <div className="sm:hidden flex flex-1"></div>
        <button onClick={handleModal} className="sm:hidden flex">
          <img
            src={
              theme === "light"
                ? "/assets/black-ham.svg"
                : "/assets/white-ham.svg"
            }
            className="h-[30px] object-cover w-[30px]"
            alt=""
          />
        </button>
        <div
          className={`flex sm:justify-center justify-start sm:relative navbar-item fixed sm:w-auto w-[250px] ${
            theme === "light"
              ? "bg-[#FFFFFF] sm:bg-transparent"
              : "sm:bg-transparent bg-[#000000]"
          } sm:flex-row sm:items-center items-start sm:pl-0 pl-[20px] sm:pt-0 pt-[30px] flex-col sm:gap-[30px] gap-[20px] top-0 bottom-0 ${
            dropdown ? "right-0" : "sm:right-0 right-[-250px]"
          }`}
        >
          <ul className="flex flex-col sm:flex-row sm:items-center justify-center items-start gap-[14px] sm:gap-[55px]">
            <li>
              <Link
                style={{ fontFamily: "GeneralSans" }}
                className={`text-[18px] font-[600] transition-all hover:text-[#992119] duration-300 leading-[28.8px] ${
                  theme === "light" ? "text-[#212121]" : "text-[#FFFFFF]"
                } ${
                  path?.pathname === "/" ? "!text-[#992119] font-[600!]" : ""
                }`}
                to="/"
              >
                Home
              </Link>
            </li>
            <li>
              <Link
                style={{ fontFamily: "GeneralSans" }}
                className={`text-[18px] font-[600] transition-all hover:text-[#992119] duration-300 leading-[28.8px] ${
                  theme === "light" ? "text-[#212121]" : "text-[#FFFFFF]"
                } ${
                  path?.pathname === "/about-us"
                    ? "!text-[#992119] font-[600!]"
                    : ""
                }`}
                to="/about-us"
              >
                About Us
              </Link>
            </li>
            <li>
              <Link
                style={{ fontFamily: "GeneralSans" }}
                className={`text-[18px] font-[600] transition-all hover:text-[#992119] duration-300 leading-[28.8px] ${
                  theme === "light" ? "text-[#212121]" : "text-[#FFFFFF]"
                } ${
                  path?.pathname === "/our-services"
                    ? "!text-[#992119] font-[600]"
                    : ""
                }`}
                to="/our-services"
              >
                Services
              </Link>
            </li>
            <li>
              <Link
                style={{ fontFamily: "GeneralSans" }}
                className={`text-[18px] font-[600] transition-all hover:text-[#992119] duration-300 leading-[28.8px] ${
                  theme === "light" ? "text-[#212121]" : "text-[#FFFFFF]"
                } ${
                  path?.pathname === "/portfolios"
                    ? "!text-[#992119] font-[600!]"
                    : ""
                }`}
                to="/portfolios"
              >
                Portfolio
              </Link>
            </li>
            <li>
              <Link
                style={{ fontFamily: "GeneralSans" }}
                className={`text-[18px] font-[600] transition-all hover:text-[#992119] duration-300 leading-[28.8px] ${
                  theme === "light" ? "text-[#212121]" : "text-[#FFFFFF]"
                } ${
                  path?.pathname === "/contact-us"
                    ? "!text-[#992119] font-[600!]"
                    : ""
                }`}
                to="/contact-us"
              >
                Contact Us
              </Link>
            </li>
            {/* <li>
              <button onClick={handleButtonClick}>Hello</button>
            </li> */}
            {/* <li>
              <Link
                className={`text-[18px] font-[600] transition-all hover:text-[#992119] duration-300 leading-[28.8px] font-[Mulish] ${
                  theme === "light" ? "text-[#212121]" : "text-[#FFFFFF]"
                } ${
                  path?.pathname === "/our-client"
                    ? "!text-[#992119] font-[600!]"
                    : ""
                }`}
                to="/our-client"
              >
                Our Clients
              </Link>
            </li> */}
            {/* <li className="block sm:hidden">
              <button
                style={{ fontFamily: "GeneralSans" }}
                onClick={() => navigate("/contact-us")}
                className="cursor-pointer font-[500] text-[18px] leading-[28.8px] transition-all duration-300 text-[#FFFFFF] hover:text-[#992119] border border-transparent hover:border-[#992119] py-[9px] px-[39px] hover:bg-[#FFFFFF] bg-[#992119] rounded-[30px]"
              >
                Contact US
              </button>
            </li> */}
            <li className="block sm:hidden">
              {/* <button
            onClick={toggleTheme}
            className="cursor-pointer block sm:hidden"
          >
            {theme === "light" ? (
              <div className="relative">
                <img src="/assets/switch.svg" className="h-[24px]" alt="" />
                <img
                  src="/assets/light-s.svg"
                  className="absolute top-[50%] translate-y-[-50%] h-[30px] left-[-3px]"
                  alt=""
                />
              </div>
            ) : (
              <div className="relative">
                <img src="/assets/switchN.svg" className="h-[24px]" alt="" />
                <img
                  src="/assets/light-n.svg"
                  className="absolute top-[50%] translate-y-[-50%] h-[30px] right-[-3px]"
                  alt=""
                />
              </div>
            )}
          </button> */}
            </li>
          </ul>
        </div>
        <div className="sm:flex hidden justify-end gap-[24px] items-center">
          {/* <button
            style={{ fontFamily: "GeneralSans" }}
            onClick={() => navigate("/contact-us")}
            className="cursor-pointer sm:block hidden font-[500] text-[18px] leading-[28.8px] transition-all duration-300 text-[#FFFFFF] hover:text-[#992119] border border-transparent hover:border-[#992119] py-[9px] px-[39px] hover:bg-[#FFFFFF] bg-[#992119] rounded-[30px]"
          >
            Contact US
          </button> */}
          {/* <button
            onClick={toggleTheme}
            className="cursor-pointer sm:block hidden"
          >
            {theme === "light" ? (
              <div className="relative">
                <img src="/assets/switch.svg" className="h-[26px]" alt="" />
                <img
                  src="/assets/light-s.svg"
                  className="absolute top-[50%] translate-y-[-50%] h-[36px] left-[-3px]"
                  alt=""
                />
              </div>
            ) : (
              <div className="relative">
                <img src="/assets/switchN.svg" className="h-[26px]" alt="" />
                <img
                  src="/assets/light-n.svg"
                  className="absolute top-[50%] translate-y-[-50%] h-[36px] right-[-3px]"
                  alt=""
                />
              </div>
            )}
          </button> */}
          {/* <Switch /> */}
        </div>
      </div>
      {modal && (
        <div className="fixed inset-0 z-50 sm:hidden flex items-center justify-center bg-gray-900 bg-opacity-50">
          <div className="bg-white p-6 rounded-lg shadow-lg max-w-sm w-full">
            <div className="flex justify-end">
              <button
                onClick={handleModal}
                className="text-gray-500 hover:text-gray-700"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-6 w-6"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              </button>
            </div>
            <ul className="flex flex-col sm:flex-row sm:items-center justify-center items-start gap-[14px] sm:gap-[55px]">
              <li>
                <Link
                  style={{ fontFamily: "GeneralSans" }}
                  className={`text-[18px] font-[600] transition-all hover:text-[#992119] duration-300 leading-[28.8px] ${
                    theme === "light" ? "text-[#212121]" : "text-[#FFFFFF]"
                  } ${
                    path?.pathname === "/" ? "!text-[#992119] font-[600!]" : ""
                  }`}
                  to="/"
                >
                  Home
                </Link>
              </li>
              <li>
                <Link
                  style={{ fontFamily: "GeneralSans" }}
                  className={`text-[18px] font-[600] transition-all hover:text-[#992119] duration-300 leading-[28.8px] ${
                    theme === "light" ? "text-[#212121]" : "text-[#FFFFFF]"
                  } ${
                    path?.pathname === "/about-us"
                      ? "!text-[#992119] font-[600!]"
                      : ""
                  }`}
                  to="/about-us"
                >
                  About Us
                </Link>
              </li>
              <li>
                <Link
                  style={{ fontFamily: "GeneralSans" }}
                  className={`text-[18px] font-[600] transition-all hover:text-[#992119] duration-300 leading-[28.8px] ${
                    theme === "light" ? "text-[#212121]" : "text-[#FFFFFF]"
                  } ${
                    path?.pathname === "/our-services"
                      ? "!text-[#992119] font-[600]"
                      : ""
                  }`}
                  to="/our-services"
                >
                  Services
                </Link>
              </li>
              <li>
                <Link
                  style={{ fontFamily: "GeneralSans" }}
                  className={`text-[18px] font-[600] transition-all hover:text-[#992119] duration-300 leading-[28.8px] ${
                    theme === "light" ? "text-[#212121]" : "text-[#FFFFFF]"
                  } ${
                    path?.pathname === "/portfolios"
                      ? "!text-[#992119] font-[600!]"
                      : ""
                  }`}
                  to="/portfolios"
                >
                  Portfolio
                </Link>
              </li>
              <li>
                <Link
                  style={{ fontFamily: "GeneralSans" }}
                  className={`text-[18px] font-[600] transition-all hover:text-[#992119] duration-300 leading-[28.8px] ${
                    theme === "light" ? "text-[#212121]" : "text-[#FFFFFF]"
                  } ${
                    path?.pathname === "/contact-us"
                      ? "!text-[#992119] font-[600!]"
                      : ""
                  }`}
                  to="/contact-us"
                >
                  Contact Us
                </Link>
              </li>
              {/* <li>
              <button onClick={handleButtonClick}>Hello</button>
            </li> */}
              {/* <li>
              <Link
                className={`text-[18px] font-[600] transition-all hover:text-[#992119] duration-300 leading-[28.8px] font-[Mulish] ${
                  theme === "light" ? "text-[#212121]" : "text-[#FFFFFF]"
                } ${
                  path?.pathname === "/our-client"
                    ? "!text-[#992119] font-[600!]"
                    : ""
                }`}
                to="/our-client"
              >
                Our Clients
              </Link>
            </li> */}
              {/* <li className="block sm:hidden">
              <button
                style={{ fontFamily: "GeneralSans" }}
                onClick={() => navigate("/contact-us")}
                className="cursor-pointer font-[500] text-[18px] leading-[28.8px] transition-all duration-300 text-[#FFFFFF] hover:text-[#992119] border border-transparent hover:border-[#992119] py-[9px] px-[39px] hover:bg-[#FFFFFF] bg-[#992119] rounded-[30px]"
              >
                Contact US
              </button>
            </li> */}
              <li className="block sm:hidden">
                {/* <button
            onClick={toggleTheme}
            className="cursor-pointer block sm:hidden"
          >
            {theme === "light" ? (
              <div className="relative">
                <img src="/assets/switch.svg" className="h-[24px]" alt="" />
                <img
                  src="/assets/light-s.svg"
                  className="absolute top-[50%] translate-y-[-50%] h-[30px] left-[-3px]"
                  alt=""
                />
              </div>
            ) : (
              <div className="relative">
                <img src="/assets/switchN.svg" className="h-[24px]" alt="" />
                <img
                  src="/assets/light-n.svg"
                  className="absolute top-[50%] translate-y-[-50%] h-[30px] right-[-3px]"
                  alt=""
                />
              </div>
            )}
          </button> */}
              </li>
            </ul>
          </div>
        </div>
      )}
    </>
  );
}

export default Header;
