import React, { useEffect } from "react";
import Header from "../components/header/Header";
import { useTheme } from "../context/ThemeContext";
import Aos from "aos";
import Footer from "../components/footer/Footer";

function OurClient() {
  const { theme } = useTheme();

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
    Aos.init({
      duration: 1000,
    });
  }, []);

  return (
    <>
      <Header />
      <div
        className={`sm:mt-[105px] mt-[60px] ${
          theme === "light" ? "bg-[#FFFFFF]" : "bg-[#000000]"
        }`}
      >
        <div className="pt-[48px] flex flex-col items-center">
          <div data-aos="fade-up">
            <p
              className={`font-[Mulish] font-[700] text-[18px] leading-[28.8px] text-center pb-[12px] ${
                theme === "light" ? "text-[#545454]" : "text-[#FFFFFF]"
              }`}
            >
              Our Client
            </p>
          </div>
          <div data-aos="fade-right">
            <h6
              style={{ fontFamily: "CabinetGrotesk" }}
              className={`max-w-[644px] pb-[20px] m-auto w-full font-[700] sm:text-[56px] text-[30px] sm:leading-[69.44px] leading-[36px] text-center ${
                theme === "light" ? "text-[#000000]" : "text-[#FFFFFF]"
              }`}
            >
              We create beautiful, practical works
            </h6>
          </div>
          <div className="sm:px-[100px] px-[20px] sm:pb-[150px] pb-[50px] pt-[53px]">
            <div className="grid sm:grid-cols-6 items-center grid-cols-4 sm:gap-y-[20px] gap-y-[14px] sm:gap-[75px] gap-[30px]">
              <div data-aos="fade-up">
                <img src="assets/logo/1.jpg" alt="" />
              </div>
              <div data-aos="fade-down">
                <img src="assets/logo/2.png" alt="" />
              </div>
              <div data-aos="fade-up">
                <img src="assets/logo/3.png" alt="" />
              </div>
              <div data-aos="fade-down">
                <img src="assets/logo/4.png" alt="" />
              </div>
              <div data-aos="fade-up">
                <img src="assets/logo/5.jpg" alt="" />
              </div>
              <div data-aos="fade-down">
                <img src="assets/logo/6.png" alt="" />
              </div>
              <div data-aos="fade-up">
                <img src="assets/logo/7.png" alt="" />
              </div>
              <div data-aos="fade-down">
                <img src="assets/logo/8.png" alt="" />
              </div>
              <div data-aos="fade-up">
                <img src="assets/logo/9.png" alt="" />
              </div>
              <div data-aos="fade-down">
                <img src="assets/logo/10.jpg" alt="" />
              </div>
              <div data-aos="fade-up">
                <img src="assets/logo/11.jpg" alt="" />
              </div>
              <div data-aos="fade-down">
                <img src="assets/logo/12.png" alt="" />
              </div>
              <div data-aos="fade-up">
                <img src="assets/logo/13.JPG" alt="" />
              </div>
              <div data-aos="fade-down">
                <img src="assets/logo/14.png" alt="" />
              </div>
              <div data-aos="fade-up">
                <img src="assets/logo/15.jpg" alt="" />
              </div>
              <div data-aos="fade-down">
                <img src="assets/logo/16.png" alt="" />
              </div>
              <div data-aos="fade-up">
                <img src="assets/logo/17.png" alt="" />
              </div>
              <div data-aos="fade-down">
                <img src="assets/logo/19.png" alt="" />
              </div>
              <div data-aos="fade-up">
                <img src="assets/logo/20.png" alt="" />
              </div>
              <div data-aos="fade-down">
                <img src="assets/logo/21.png" alt="" />
              </div>
              <div data-aos="fade-down">
                <img src="assets/logo/22.png" alt="" />
              </div>
              <div data-aos="fade-down">
                <img src="assets/logo/23.png" alt="" />
              </div>
              <div data-aos="fade-down">
                <img src="assets/logo/24.png" alt="" />
              </div>
              <div data-aos="fade-down">
                <img src="assets/logo/25.jpg" alt="" />
              </div>
              <div data-aos="fade-down">
                <img src="assets/logo/26.jpg" alt="" />
              </div>
              <div data-aos="fade-down">
                <img src="assets/logo/27.jpg" alt="" />
              </div>
              <div data-aos="fade-down">
                <img src="assets/logo/28.jpg" alt="" />
              </div>
              <div data-aos="fade-down">
                <img src="assets/logo/29.jpg" alt="" />
              </div>
              <div data-aos="fade-down">
                <img src="assets/logo/30.jpg" alt="" />
              </div>
              <div data-aos="fade-down">
                <img src="assets/logo/31.png" alt="" />
              </div>
              <div data-aos="fade-down">
                <img src="assets/logo/32.png" alt="" />
              </div>
              <div data-aos="fade-down">
                <img src="assets/logo/33.png" alt="" />
              </div>
              <div data-aos="fade-down">
                <img src="assets/logo/34.png" alt="" />
              </div>
              <div data-aos="fade-down">
                <img src="assets/logo/35.jpeg" alt="" />
              </div>
              <div data-aos="fade-down">
                <img src="assets/logo/36.png" alt="" />
              </div>
              <div data-aos="fade-down">
                <img src="assets/logo/37.jpg" alt="" />
              </div>
              <div data-aos="fade-down">
                <img src="assets/logo/38.jpg" alt="" />
              </div>
              <div data-aos="fade-down">
                <img src="assets/logo/39.jpg" alt="" />
              </div>
              <div data-aos="fade-down">
                <img src="assets/logo/40.jpg" alt="" />
              </div>
              <div data-aos="fade-down">
                <img src="assets/logo/41.jpg" alt="" />
              </div>
              <div data-aos="fade-down">
                <img src="assets/logo/42.jpg" alt="" />
              </div>
              <div data-aos="fade-down">
                <img src="assets/logo/43.jpg" alt="" />
              </div>
              <div data-aos="fade-down">
                <img src="assets/logo/44.jpg" alt="" />
              </div>
              <div data-aos="fade-down">
                <img src="assets/logo/45.png" alt="" />
              </div>
              <div data-aos="fade-down">
                <img src="assets/logo/46.png" alt="" />
              </div>
              <div data-aos="fade-down">
                <img src="assets/logo/47.jpg" alt="" />
              </div>
              <div data-aos="fade-down">
                <img src="assets/logo/48.png" alt="" />
              </div>
              <div data-aos="fade-down">
                <img src="assets/logo/49.png" alt="" />
              </div>
              <div data-aos="fade-down">
                <img src="assets/logo/50.png" alt="" />
              </div>
              <div data-aos="fade-down">
                <img src="assets/logo/51.png" alt="" />
              </div>
              <div data-aos="fade-down">
                <img src="assets/logo/52.png" alt="" />
              </div>
              <div data-aos="fade-down">
                <img src="assets/logo/53.png" alt="" />
              </div>
              <div data-aos="fade-down">
                <img src="assets/logo/54.jpg" alt="" />
              </div>
              <div data-aos="fade-down">
                <img src="assets/logo/55.jpg" alt="" />
              </div>
              <div data-aos="fade-down">
                <img src="assets/logo/56.jpg" alt="" />
              </div>
              <div data-aos="fade-down">
                <img src="assets/logo/57.png" alt="" />
              </div>
              <div data-aos="fade-down">
                <img src="assets/logo/58.png" alt="" />
              </div>
              <div data-aos="fade-down">
                <img src="assets/logo/59.png" alt="" />
              </div>
              <div data-aos="fade-down">
                <img src="assets/logo/60.png" alt="" />
              </div>
              <div data-aos="fade-down">
                <img src="assets/logo/61.jpg" alt="" />
              </div>
              <div data-aos="fade-down">
                <img src="assets/logo/62.png" alt="" />
              </div>
              <div data-aos="fade-down">
                <img src="assets/logo/63.png" alt="" />
              </div>
              <div data-aos="fade-down">
                <img src="assets/logo/64.jpg" alt="" />
              </div>
              <div data-aos="fade-down">
                <img src="assets/logo/65.png" alt="" />
              </div>
              <div data-aos="fade-down">
                <img src="assets/logo/66.png" alt="" />
              </div>
              <div data-aos="fade-down">
                <img src="assets/logo/67.png" alt="" />
              </div>
              <div data-aos="fade-down">
                <img src="assets/logo/68.png" alt="" />
              </div>
              <div data-aos="fade-down">
                <img src="assets/logo/69.png" alt="" />
              </div>
              <div data-aos="fade-down">
                <img src="assets/logo/70.png" alt="" />
              </div>
              <div data-aos="fade-down">
                <img src="assets/logo/71.png" alt="" />
              </div>
              <div data-aos="fade-down">
                <img src="assets/logo/72.png" alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default OurClient;
