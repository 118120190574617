import React from "react";
import { Link } from "react-router-dom";
import Slider from "react-slick";

function WeSlider() {
  const settings = {
    arrows: false,
    dots: false,
    className: "center",
    centerMode: true,
    infinite: true,
    centerPadding: "60px",
    slidesToShow: 2,
    speed: 2000,
    autoplay: true,
    autoplaySpeed: 2000,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  return (
    <>
      <Slider {...settings}>
        <div>
          <div className="max-w-[95%] we-card relative w-full m-auto h-[448px] rounded-[16px]">
            <img
              src="assets/about/photo5.png"
              className="w-full object-cover m-auto  h-[448px] rounded-[16px]"
              alt=""
            />
             <div className="bg-[#992119] border max-w-[517px] we-body w-full flex sm:flex-row flex-col items-center justify-between border-[#E84037] absolute sm:right-[36px] right-0 sm:gap-0 gap-[14px] bottom-[30px] rounded-[16px] px-[26px] py-[22px]">
                <h6
                  data-aos="fade-right"
                  style={{ fontFamily: "GeneralSans" }}
                  className="font-[800] italic sm:text-[24px] text-[16px] sm:leading-[26px] leading-[22px] text-[#FFFFFF]"
                >
                  Digital Campaigns
                </h6>
                <Link to="/our-work">
                  <button
                    data-aos="fade-left"
                    style={{ fontFamily: "GeneralSans" }}
                    className="border border-[#FFFFFF] pl-[16px] rounded-[30px] gap-[18px] flex items-center justify-center font-[600] text-[18px] leading-[28.8px] text-[#FFFFFF] py-[3px] pr-[4px]"
                  >
                    View <img src="assets/port/arrow.svg" className="sm:h-full h-[24px]" alt="" />
                  </button>
                </Link>
              </div>
          </div>
        </div>
        <div>
          <div className="max-w-[95%] we-card relative w-full m-auto h-[448px] rounded-[16px]">
            <img
              src="assets/about/photo6.png"
              className="w-full object-cover m-auto rounded-[16px] h-[448px]"
              alt=""
            />
             <div className="sm:flex-row flex-col bg-[#992119] border max-w-[517px] we-body w-full flex items-center justify-between border-[#E84037] absolute sm:right-[36px] right-0 sm:gap-0 gap-[14px] bottom-[30px] rounded-[16px] px-[26px] py-[22px]">
                <h6
                  data-aos="fade-right"
                  style={{ fontFamily: "GeneralSans" }}
                  className="font-[800] italic sm:text-[24px] text-[16px] sm:leading-[26px] leading-[22px] text-[#FFFFFF]"
                >
                  Digital Campaigns
                </h6>
                <Link to="/our-work">
                  <button
                    data-aos="fade-left"
                    style={{ fontFamily: "GeneralSans" }}
                    className="border border-[#FFFFFF] pl-[16px] rounded-[30px] gap-[18px] flex items-center justify-center font-[600] text-[18px] leading-[28.8px] text-[#FFFFFF] py-[3px] pr-[4px]"
                  >
                    View <img src="assets/port/arrow.svg" className="sm:h-full h-[24px]" alt="" />
                  </button>
                </Link>
              </div>
          </div>
        </div>
      
        <div>
          <div className="max-w-[95%] we-card relative w-full m-auto h-[448px] rounded-[16px]">
            <img
              src="assets/about/photo5.png"
              className="w-full object-cover m-auto  h-[448px] rounded-[16px]"
              alt=""
            />
             <div className="sm:flex-row flex-col bg-[#992119] border max-w-[517px] we-body w-full flex items-center justify-between border-[#E84037] absolute sm:right-[36px] right-0 sm:gap-0 gap-[14px] bottom-[30px] rounded-[16px] px-[26px] py-[22px]">
                <h6
                  data-aos="fade-right"
                  style={{ fontFamily: "GeneralSans" }}
                  className="font-[800] italic sm:text-[24px] text-[16px] sm:leading-[26px] leading-[22px] text-[#FFFFFF]"
                >
                  Digital Campaigns
                </h6>
                <Link to="/our-work">
                  <button
                    data-aos="fade-left"
                    style={{ fontFamily: "GeneralSans" }}
                    className="border border-[#FFFFFF] pl-[16px] rounded-[30px] gap-[18px] flex items-center justify-center font-[600] text-[18px] leading-[28.8px] text-[#FFFFFF] py-[3px] pr-[4px]"
                  >
                    View <img src="assets/port/arrow.svg" className="sm:h-full h-[24px]" alt="" />
                  </button>
                </Link>
              </div>
          </div>
        </div>
        <div>
          <div className="max-w-[95%] we-card relative w-full m-auto h-[448px] rounded-[16px]">
            <img
              src="assets/about/photo6.png"
              className="w-full object-cover m-auto rounded-[16px] h-[448px]"
              alt=""
            />
             <div className="sm:flex-row flex-col bg-[#992119] border max-w-[517px] we-body w-full flex items-center justify-between border-[#E84037] absolute sm:right-[36px] right-0 sm:gap-0 gap-[14px] bottom-[30px] rounded-[16px] px-[26px] py-[22px]">
                <h6
                  data-aos="fade-right"
                  style={{ fontFamily: "GeneralSans" }}
                  className="font-[800] italic sm:text-[24px] text-[16px] sm:leading-[26px] leading-[22px] text-[#FFFFFF]"
                >
                  Digital Campaigns
                </h6>
                <Link to="/our-work">
                  <button
                    data-aos="fade-left"
                    style={{ fontFamily: "GeneralSans" }}
                    className="border border-[#FFFFFF] pl-[16px] rounded-[30px] gap-[18px] flex items-center justify-center font-[600] text-[18px] leading-[28.8px] text-[#FFFFFF] py-[3px] pr-[4px]"
                  >
                    View <img src="assets/port/arrow.svg" className="sm:h-full h-[24px]" alt="" />
                  </button>
                </Link>
              </div>
          </div>
        </div>
      
        <div>
          <div className="max-w-[95%] we-card relative w-full m-auto h-[448px] rounded-[16px]">
            <img
              src="assets/about/photo5.png"
              className="w-full object-cover m-auto  h-[448px] rounded-[16px]"
              alt=""
            />
             <div className="sm:flex-row flex-col bg-[#992119] border max-w-[517px] we-body w-full flex items-center justify-between border-[#E84037] absolute sm:right-[36px] right-0 sm:gap-0 gap-[14px] bottom-[30px] rounded-[16px] px-[26px] py-[22px]">
                <h6
                  data-aos="fade-right"
                  style={{ fontFamily: "GeneralSans" }}
                  className="font-[800] italic sm:text-[24px] text-[16px] sm:leading-[26px] leading-[22px] text-[#FFFFFF]"
                >
                  Digital Campaigns
                </h6>
                <Link to="/our-work">
                  <button
                    data-aos="fade-left"
                    style={{ fontFamily: "GeneralSans" }}
                    className="border border-[#FFFFFF] pl-[16px] rounded-[30px] gap-[18px] flex items-center justify-center font-[600] text-[18px] leading-[28.8px] text-[#FFFFFF] py-[3px] pr-[4px]"
                  >
                    View <img src="assets/port/arrow.svg" className="sm:h-full h-[24px]" alt="" />
                  </button>
                </Link>
              </div>
          </div>
        </div>
        <div>
          <div className="max-w-[95%] we-card relative w-full m-auto h-[448px] rounded-[16px]">
            <img
              src="assets/about/photo6.png"
              className="w-full object-cover m-auto rounded-[16px] h-[448px]"
              alt=""
            />
             <div className="sm:flex-row flex-col bg-[#992119] border max-w-[517px] we-body w-full flex items-center justify-between border-[#E84037] absolute sm:right-[36px] right-0 sm:gap-0 gap-[14px] bottom-[30px] rounded-[16px] px-[26px] py-[22px]">
                <h6
                  data-aos="fade-right"
                  style={{ fontFamily: "GeneralSans" }}
                  className="font-[800] italic sm:text-[24px] text-[16px] sm:leading-[26px] leading-[22px] text-[#FFFFFF]"
                >
                  Digital Campaigns
                </h6>
                <Link to="/our-work">
                  <button
                    data-aos="fade-left"
                    style={{ fontFamily: "GeneralSans" }}
                    className="border border-[#FFFFFF] pl-[16px] rounded-[30px] gap-[18px] flex items-center justify-center font-[600] text-[18px] leading-[28.8px] text-[#FFFFFF] py-[3px] pr-[4px]"
                  >
                    View <img src="assets/port/arrow.svg" className="sm:h-full h-[24px]" alt="" />
                  </button>
                </Link>
              </div>
          </div>
        </div>
      
        <div>
          <div className="max-w-[95%] we-card relative w-full m-auto h-[448px] rounded-[16px]">
            <img
              src="assets/about/photo5.png"
              className="w-full object-cover m-auto  h-[448px] rounded-[16px]"
              alt=""
            />
             <div className="sm:flex-row flex-col bg-[#992119] border max-w-[517px] we-body w-full flex items-center justify-between border-[#E84037] absolute sm:right-[36px] right-0 sm:gap-0 gap-[14px] bottom-[30px] rounded-[16px] px-[26px] py-[22px]">
                <h6
                  data-aos="fade-right"
                  style={{ fontFamily: "GeneralSans" }}
                  className="font-[800] italic sm:text-[24px] text-[16px] sm:leading-[26px] leading-[22px] text-[#FFFFFF]"
                >
                  Digital Campaigns
                </h6>
                <Link to="/our-work">
                  <button
                    data-aos="fade-left"
                    style={{ fontFamily: "GeneralSans" }}
                    className="border border-[#FFFFFF] pl-[16px] rounded-[30px] gap-[18px] flex items-center justify-center font-[600] text-[18px] leading-[28.8px] text-[#FFFFFF] py-[3px] pr-[4px]"
                  >
                    View <img src="assets/port/arrow.svg" className="sm:h-full h-[24px]" alt="" />
                  </button>
                </Link>
              </div>
          </div>
        </div>
        <div>
          <div className="max-w-[95%] we-card relative w-full m-auto h-[448px] rounded-[16px]">
            <img
              src="assets/about/photo6.png"
              className="w-full object-cover m-auto rounded-[16px] h-[448px]"
              alt=""
            />
             <div className="sm:flex-row flex-col bg-[#992119] border max-w-[517px] we-body w-full flex items-center justify-between border-[#E84037] absolute sm:right-[36px] right-0 sm:gap-0 gap-[14px] bottom-[30px] rounded-[16px] px-[26px] py-[22px]">
                <h6
                  data-aos="fade-right"
                  style={{ fontFamily: "GeneralSans" }}
                  className="font-[800] italic sm:text-[24px] text-[16px] sm:leading-[26px] leading-[22px] text-[#FFFFFF]"
                >
                  Digital Campaigns
                </h6>
                <Link to="/our-work">
                  <button
                    data-aos="fade-left"
                    style={{ fontFamily: "GeneralSans" }}
                    className="border border-[#FFFFFF] pl-[16px] rounded-[30px] gap-[18px] flex items-center justify-center font-[600] text-[18px] leading-[28.8px] text-[#FFFFFF] py-[3px] pr-[4px]"
                  >
                    View <img src="assets/port/arrow.svg" className="sm:h-full h-[24px]" alt="" />
                  </button>
                </Link>
              </div>
          </div>
        </div>
      
        <div>
          <div className="max-w-[95%] we-card relative w-full m-auto h-[448px] rounded-[16px]">
            <img
              src="assets/about/photo5.png"
              className="w-full object-cover m-auto  h-[448px] rounded-[16px]"
              alt=""
            />
             <div className="sm:flex-row flex-col bg-[#992119] border max-w-[517px] we-body w-full flex items-center justify-between border-[#E84037] absolute sm:right-[36px] right-0 sm:gap-0 gap-[14px] bottom-[30px] rounded-[16px] px-[26px] py-[22px]">
                <h6
                  data-aos="fade-right"
                  style={{ fontFamily: "GeneralSans" }}
                  className="font-[800] italic sm:text-[24px] text-[16px] sm:leading-[26px] leading-[22px] text-[#FFFFFF]"
                >
                  Digital Campaigns
                </h6>
                <Link to="/our-work">
                  <button
                    data-aos="fade-left"
                    style={{ fontFamily: "GeneralSans" }}
                    className="border border-[#FFFFFF] pl-[16px] rounded-[30px] gap-[18px] flex items-center justify-center font-[600] text-[18px] leading-[28.8px] text-[#FFFFFF] py-[3px] pr-[4px]"
                  >
                    View <img src="assets/port/arrow.svg" className="sm:h-full h-[24px]" alt="" />
                  </button>
                </Link>
              </div>
          </div>
        </div>
        <div>
          <div className="max-w-[95%] we-card relative w-full m-auto h-[448px] rounded-[16px]">
            <img
              src="assets/about/photo6.png"
              className="w-full object-cover m-auto rounded-[16px] h-[448px]"
              alt=""
            />
             <div className="sm:flex-row flex-col bg-[#992119] border max-w-[517px] we-body w-full flex items-center justify-between border-[#E84037] absolute sm:right-[36px] right-0 sm:gap-0 gap-[14px] bottom-[30px] rounded-[16px] px-[26px] py-[22px]">
                <h6
                  data-aos="fade-right"
                  style={{ fontFamily: "GeneralSans" }}
                  className="font-[800] italic sm:text-[24px] text-[16px] sm:leading-[26px] leading-[22px] text-[#FFFFFF]"
                >
                  Digital Campaigns
                </h6>
                <Link to="/our-work">
                  <button
                    data-aos="fade-left"
                    style={{ fontFamily: "GeneralSans" }}
                    className="border border-[#FFFFFF] pl-[16px] rounded-[30px] gap-[18px] flex items-center justify-center font-[600] text-[18px] leading-[28.8px] text-[#FFFFFF] py-[3px] pr-[4px]"
                  >
                    View <img src="assets/port/arrow.svg" className="sm:h-full h-[24px]" alt="" />
                  </button>
                </Link>
              </div>
          </div>
        </div>
      
        <div>
          <div className="max-w-[95%] we-card relative w-full m-auto h-[448px] rounded-[16px]">
            <img
              src="assets/about/photo5.png"
              className="w-full object-cover m-auto  h-[448px] rounded-[16px]"
              alt=""
            />
             <div className="sm:flex-row flex-col bg-[#992119] border max-w-[517px] we-body w-full flex items-center justify-between border-[#E84037] absolute sm:right-[36px] right-0 sm:gap-0 gap-[14px] bottom-[30px] rounded-[16px] px-[26px] py-[22px]">
                <h6
                  data-aos="fade-right"
                  style={{ fontFamily: "GeneralSans" }}
                  className="font-[800] italic sm:text-[24px] text-[16px] sm:leading-[26px] leading-[22px] text-[#FFFFFF]"
                >
                  Digital Campaigns
                </h6>
                <Link to="/our-work">
                  <button
                    data-aos="fade-left"
                    style={{ fontFamily: "GeneralSans" }}
                    className="border border-[#FFFFFF] pl-[16px] rounded-[30px] gap-[18px] flex items-center justify-center font-[600] text-[18px] leading-[28.8px] text-[#FFFFFF] py-[3px] pr-[4px]"
                  >
                    View <img src="assets/port/arrow.svg" className="sm:h-full h-[24px]" alt="" />
                  </button>
                </Link>
              </div>
          </div>
        </div>
        <div>
          <div className="max-w-[95%] we-card relative w-full m-auto h-[448px] rounded-[16px]">
            <img
              src="assets/about/photo6.png"
              className="w-full object-cover m-auto rounded-[16px] h-[448px]"
              alt=""
            />
             <div className="sm:flex-row flex-col bg-[#992119] border max-w-[517px] we-body w-full flex items-center justify-between border-[#E84037] absolute sm:right-[36px] right-0 sm:gap-0 gap-[14px] bottom-[30px] rounded-[16px] px-[26px] py-[22px]">
                <h6
                  data-aos="fade-right"
                  style={{ fontFamily: "GeneralSans" }}
                  className="font-[800] italic sm:text-[24px] text-[16px] sm:leading-[26px] leading-[22px] text-[#FFFFFF]"
                >
                  Digital Campaigns
                </h6>
                <Link to="/our-work">
                  <button
                    data-aos="fade-left"
                    style={{ fontFamily: "GeneralSans" }}
                    className="border border-[#FFFFFF] pl-[16px] rounded-[30px] gap-[18px] flex items-center justify-center font-[600] text-[18px] leading-[28.8px] text-[#FFFFFF] py-[3px] pr-[4px]"
                  >
                    View <img src="assets/port/arrow.svg" className="sm:h-full h-[24px]" alt="" />
                  </button>
                </Link>
              </div>
          </div>
        </div>
      
      </Slider>
    </>
  );
}

export default WeSlider;
