import React, { useEffect } from "react";
import Footer from "../components/footer/Footer";
import Header from "../components/header/Header";
import { useNavigate } from "react-router-dom";
import Aos from "aos";
import { useTheme } from "../context/ThemeContext";

function Hunter() {
  const navigate = useNavigate();
  const { theme } = useTheme();

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });

    Aos.init({
      duration: 1000,
    });
  }, []);

  return (
    <>
      <Header />
      <div
        className={`sm:mt-[104px] mt-[60px] ${
          theme === "light" ? "bg-[#ffffff]" : "bg-[#171717]"
        }`}
      >
        <div className="sm:pt-[70px] pt-[30px] sm:px-[72px] px-[20px] sm:pb-[100px] pb-[40px] flex justify-between items-center">
          <div className="sm:flex hidden flex-1"></div>
          <div className="flex flex-1 sm:justify-center justify-start">
            <h6 style={{ fontFamily: "GeneralSans" }}
              className={`font-[700] italic sm:text-[48px] text-[20px] leading-[26px] ${
                theme === "light" ? "text-[#000000]" : "text-[#FFFFFF]"
              }`}
            >
              Hunter Beer
            </h6>
          </div>
          <div className="flex flex-1 justify-end">
            <button onClick={() => navigate(-1)}>
              <img
                src="/assets/port/close.svg"
                className="sm:h-auto h-[40px]"
                alt=""
              />
            </button>
          </div>
        </div>
      </div>
      <div
        className={`sm:px-[40px] px-[20px] ${
          theme === "light" ? "bg-[#ffffff]" : "bg-[#171717]"
        }`}
      >
        <div className="grid sm:grid-cols-2 grid-cols-1 sm:gap-[16px] gap-0 gap-y-[16px]">
          <div className="sm:col-span-2 col-span-1">
            <div className="flex w-full items-center justify-center">
              <img data-aos="fade-up" src="/assets/port/photo9.png" alt="" />
            </div>
          </div>
          <div className="flex w-full items-center justify-center">
            <img data-aos="fade-up" src="/assets/port/photo10.png" alt="" />
          </div>
          <div className="flex w-full items-center justify-center">
            <img data-aos="fade-up" src="/assets/port/photo11.png" alt="" />
          </div>
          <div className="sm:col-span-2 sm:py-[180px] py-[40px] col-span-1">
            <div className="flex w-full items-center justify-center">
              <img data-aos="fade-up" src="/assets/port/photo12.png" alt="" />
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default Hunter;
