import React, { useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import Aos from "aos";

function Footer() {
  const path = useLocation();

  const pathname = path?.pathname;

  useEffect(() => {
    Aos.init({
      duration: 1000,
    });
  }, []);

  return (
    <>
      <div className="bg-[#1C1C1C] overflow-hidden sm:px-[100px] px-[20px] sm:pt-[53px] pt-[30px] sm:pb-[71px] pb-[30px]">
        <div className="grid sm:grid-cols-3 grid-cols-1 sm:gap-[96px] gap-[30px] items-center">
          <div>
            <img
              data-aos="fade-down"
              src="assets/home/footer-logo.svg"
              alt=""
            />
            <p
              data-aos="fade-up"
              style={{ fontFamily: "GeneralSans" }}
              className="pt-[15px] font-[400] text-[16px] leading-[24px] text-[#FFFFFF]"
            >
              At Galiyaara, we’re all about bringing your stories to life. We’re
              not just about production, we’re about passion, creativity, and
              making your vision come alive
            </p>
          </div>
          <ul className="flex flex-col">
            <li
              data-aos="fade-up"
              className="py-[16px] border-b flex items-center justify-between border-[#343839]"
            >
              <Link
                to="/"
                style={{ fontFamily: "GeneralSans" }}
                className="font-[500] text-[16px] leading-[24px] text-[#FFFFFF]"
              >
                Home
              </Link>
              <img
                data-aos="fade-up"
                src={pathname === "/" ? "assets/icon1.svg" : "assets/icon2.svg"}
                alt=""
              />
            </li>
            <li
              data-aos="fade-up"
              className="py-[16px] border-b flex items-center justify-between border-[#343839]"
            >
              <Link
                to="/about-us"
                style={{ fontFamily: "GeneralSans" }}
                className="font-[500] text-[16px] leading-[24px] text-[#FFFFFF]"
              >
                About Us
              </Link>
              <img
                data-aos="fade-up"
                src={
                  pathname === "/about-us"
                    ? "assets/icon1.svg"
                    : "assets/icon2.svg"
                }
                alt=""
              />
            </li>
            <li
              data-aos="fade-up"
              className="py-[16px] border-b flex items-center justify-between border-[#343839]"
            >
              <Link
                to="/portfolios"
                style={{ fontFamily: "GeneralSans" }}
                className="font-[500] text-[16px] leading-[24px] text-[#FFFFFF]"
              >
                Portfolio
              </Link>
              <img
                data-aos="fade-up"
                src={
                  pathname === "/portfolios"
                    ? "assets/icon1.svg"
                    : "assets/icon2.svg"
                }
                alt=""
              />
            </li>
            <li
              data-aos="fade-up"
              className="py-[16px] border-b flex items-center justify-between border-[#343839]"
            >
              <Link
                to="/our-services"
                style={{ fontFamily: "GeneralSans" }}
                className="font-[500] text-[16px] leading-[24px] text-[#FFFFFF]"
              >
                Services
              </Link>
              <img
                data-aos="fade-up"
                src={
                  pathname === "/our-services"
                    ? "assets/icon1.svg"
                    : "assets/icon2.svg"
                }
                alt=""
              />
            </li>
            <li
              data-aos="fade-up"
              className="py-[16px] flex items-center justify-between"
            >
              <Link
                to="/contact-us"
                style={{ fontFamily: "GeneralSans" }}
                className="font-[500] text-[16px] leading-[24px] text-[#FFFFFF]"
              >
                Contact Us
              </Link>
              <img
                src={
                  pathname === "/contact-us"
                    ? "assets/icon1.svg"
                    : "assets/icon2.svg"
                }
                alt=""
              />
            </li>
          </ul>
          <div className="flex flex-col justify-end">
            <h6
              style={{ fontFamily: "GeneralSans" }}
              className="font-[300] text-[14px] leading-[20px] text-[#FFFFFF99] sm:pb-[30px] pb-[16px]"
            >
              Get Latest Updates
            </h6>
            <input
              type="text"
              style={{ fontFamily: "GeneralSans" }}
              className="bg-[#0F0F0F] rounded-[34px] border-[1.62px] p-[13px] border-[#A1AEBF] font-[400] text-[12.98px] leading-[16.23px] text-[#FFFFFF]"
              placeholder="Your Email"
            />
          </div>
        </div>
      </div>
    </>
  );
}

export default Footer;
