import React from 'react'
import Slider from 'react-slick';

function LineSlider() {
    const settings = {
        dots: false,
        infinite: true,
        slidesToShow: 3,
        slidesToScroll: 1,
        autoplay: true,
        speed: 2000,
        autoplaySpeed: 2000,
        cssEase: "linear",
        responsive: [
            {
              breakpoint: 600,
              settings: {
                slidesToShow: 1,
              }
            },
            {
              breakpoint: 480,
              settings: {
                slidesToShow: 1,
              }
            }
          ]
      };

  return (
    <>
 <div className="slider-container">
      <Slider {...settings}>
        <div>
          <div className="flex items-center">
          <h6 className="font-[700] sm:text-[36px] text-[24px] w-full sm:leading-[44.64px] leading-[30px] text-[#FDFBF9]">
            Film Production House
          </h6>
          <img src="assets/home/vector2.svg" className='sm:h-auto h-[20px]' alt="" />
          </div>
        </div>
        <div>
          <div className="flex items-center">
          <h6 className="font-[700] sm:text-[36px] text-[24px] w-full sm:leading-[44.64px] leading-[30px] text-[#FDFBF9]">
            Digital Media Services
          </h6>
          <img src="assets/home/vector2.svg" className='sm:h-auto h-[20px]' alt="" />
          </div>
        </div>
        <div>
          <div className="flex items-center">
          <h6 className="font-[700] sm:text-[36px] text-[24px] w-full sm:leading-[44.64px] leading-[30px] text-[#FDFBF9]">
            Advertising Agency
          </h6>
          <img src="assets/home/vector2.svg" className='sm:h-auto h-[20px]' alt="" />
          </div>
        </div>
        <div>
         <div className="flex items-center">
         <h6 className="font-[700] sm:text-[36px] text-[24px] w-full sm:leading-[44.64px] leading-[30px] text-[#FDFBF9]">
            Creative Services
          </h6>
          <img src="assets/home/vector2.svg" className='sm:h-auto h-[20px]' alt="" />
         </div>
        </div>
        <div>
          <div className="flex items-center">
          <h6 className="font-[700] sm:text-[36px] text-[24px] w-full sm:leading-[44.64px] leading-[30px] text-[#FDFBF9]">
            Film Production House
          </h6>
          <img src="assets/home/vector2.svg" className='sm:h-auto h-[20px]' alt="" />
          </div>
        </div>
        <div>
          <div className="flex items-center">
          <h6 className="font-[700] sm:text-[36px] text-[24px] w-full sm:leading-[44.64px] leading-[30px] text-[#FDFBF9]">
            Digital Media Services
          </h6>
          <img src="assets/home/vector2.svg" className='sm:h-auto h-[20px]' alt="" />
          </div>
        </div>
        <div>
          <div className="flex items-center">
          <h6 className="font-[700] sm:text-[36px] text-[24px] w-full sm:leading-[44.64px] leading-[30px] text-[#FDFBF9]">
            Advertising Agency
          </h6>
          <img src="assets/home/vector2.svg" className='sm:h-auto h-[20px]' alt="" />
          </div>
        </div>
        <div>
         <div className="flex items-center">
         <h6 className="font-[700] sm:text-[36px] text-[24px] w-full sm:leading-[44.64px] leading-[30px] text-[#FDFBF9]">
            Creative Services
          </h6>
          <img src="assets/home/vector2.svg" className='sm:h-auto h-[20px]' alt="" />
         </div>
        </div>
        <div>
          <div className="flex items-center">
          <h6 className="font-[700] sm:text-[36px] text-[24px] w-full sm:leading-[44.64px] leading-[30px] text-[#FDFBF9]">
            Film Production House
          </h6>
          <img src="assets/home/vector2.svg" className='sm:h-auto h-[20px]' alt="" />
          </div>
        </div>
        <div>
          <div className="flex items-center">
          <h6 className="font-[700] sm:text-[36px] text-[24px] w-full sm:leading-[44.64px] leading-[30px] text-[#FDFBF9]">
            Digital Media Services
          </h6>
          <img src="assets/home/vector2.svg" className='sm:h-auto h-[20px]' alt="" />
          </div>
        </div>
        <div>
          <div className="flex items-center">
          <h6 className="font-[700] sm:text-[36px] text-[24px] w-full sm:leading-[44.64px] leading-[30px] text-[#FDFBF9]">
            Advertising Agency
          </h6>
          <img src="assets/home/vector2.svg" className='sm:h-auto h-[20px]' alt="" />
          </div>
        </div>
        <div>
         <div className="flex items-center">
         <h6 className="font-[700] sm:text-[36px] text-[24px] w-full sm:leading-[44.64px] leading-[30px] text-[#FDFBF9]">
            Creative Services
          </h6>
          <img src="assets/home/vector2.svg" className='sm:h-auto h-[20px]' alt="" />
         </div>
        </div>
      </Slider>
    </div>
    </>
  )
}

export default LineSlider