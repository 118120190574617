import React, { useEffect, useState } from "react";
import Header from "../components/header/Header";
import WeSlider from "../components/slider/WeSlider";
import OurSlider from "../components/slider/OurSlider";
import Footer from "../components/footer/Footer";
import Aos from "aos";
import { useTheme } from "../context/ThemeContext";
import LineSlider from "../components/slider/LineSlider";
import { Link, useNavigate } from "react-router-dom";

function Home() {
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState("");
  const { theme } = useTheme();

  const [selectedButtons, setSelectedButtons] = useState([]);

  const toggleButton = (button) => {
    setSelectedButtons((prevSelected) =>
      prevSelected.includes(button)
        ? prevSelected.filter((b) => b !== button)
        : [...prevSelected, button]
    );
  };

  const buttonData = [
    "Film Production House",
    "Social media marketing",
    "Creative Media Agency",
  ];

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
    Aos.init({
      duration: 1000,
    });
  }, []);

  return (
    <>
      <Header />
      <div
        className={`relative h-full sm:px-0 px-[30px] sm:pt-[60px] sm:pb-[56px] pb-[20px]  pt-[30px] sm:mt-[105px] mt-[60px] ${
          theme === "light" ? "bg-[#FFFFFF]" : "bg-[#0e0e0e]"
        }`}
      >
        <div data-aos="fade-down">
          <div className="max-w-[900px] relative z-1 w-full m-auto ">
            <h1
              style={{ fontFamily: "GeneralSans" }}
              className={`capitalize pb-[14px] mt-[-20px] text-center z-10 font-[600] sm:text-[56px] text-[18px] sm:leading-[68.26px] leading-[24px] ${
                theme === "light" ? "text-[#0e0e0e]" : "text-[#FFFFFF]"
              }`}
            >
              "STORIES THAT CAPTIVATE”
            </h1>
            <div className="flex flex-col absolute sm:w-[68%] w-[60%] left-[50%] translate-x-[-50%] sm:bottom-[30px] bottom-[20px] z-[-1] gap-[2px]">
              <div className="sm:h-[12px] h-[3px] bg-[#e84037] w-full"></div>
              <div className="sm:h-[2px] h-[1px] bg-[#e84037] w-full"></div>
            </div>
          </div>
        </div>
        <div data-aos="fade-up">
          <p
            style={{ fontFamily: "GeneralSans" }}
            className={`max-w-[900px] m-auto text-center font-[400] text-[18px] leading-[27px] text-[#545454] ${
              theme === "light" ? "text-[#0e0e0e]" : "text-[#FFFFFF]"
            }`}
          >
            <span className="font-medium">
              {" "}
              At Galiyaara, we’re all about bringing your stories to life.
            </span>{" "}
            <br />
            We’re not just about production, we’re about passion, creativity,
            and making your vision come alive
          </p>
        </div>
      </div>
      <img
        src="assets/home/photo1.svg"
        className="relative w-full sm:h-auto h-[373px] object-cover "
        alt="hero-bg"
      />
      <div
        className={`overflow-hidden pt-[80px] sm:px-[100px] px-[30px] pb-[77px] ${
          theme === "light" ? "bg-[#E84037]" : "bg-[#1C1C1C]"
        }`}
      >
        <div
          style={{ fontFamily: "GeneralSans" }}
          className="flex items-center flex-col sm:gap-[36px] gap-[20px]"
        >
          <div
            data-aos="fade-left"
            className="flex flex-col justify-between items-center max-w-[228px] w-full gap-[15px]"
          >
            <h6 className="font-[700] sm:text-[40px] text-[30px] sm:leading-[50.51px] leading-[38px] text-[#FFFFFF]">
              About Us
            </h6>
            <img
              src="assets/home/vector1.svg"
              className="sm:w-[60%] w-[40%]"
              alt=""
            />
          </div>
          <div className="flex flex-col max-w-[1060px] m-auto w-full">
            {/* <h6
              data-aos="fade-right"
              style={{ fontFamily: "GeneralSans" }}
              className="font-[800] text-[24px] text-center leading-[38.4px] text-[#FFFFFF]"
            >
              At Galiyaara, we’re all about bringing your stories to life.
            </h6> */}
            <p
              data-aos="fade-left"
              style={{ fontFamily: "GeneralSans" }}
              className="pt-[4px] font-[600] sm:text-center text-justify text-[18px] leading-[28.8px] text-[#FFFFFF]"
            >
              At Galiyaara, we’re all about bringing your stories to life.
            </p>
            <p
              style={{ fontFamily: "GeneralSans" }}
              data-aos="fade-left"
              className="pt-[4px] sm:text-center text-left font-[300] text-[18px] leading-[28.8px] text-[#FFFFFF]"
            >
              We’re your creative partner for everything from film production to
              digital marketing, We specialize in everything from engaging
              corporate videos and impactful documentaries and to eye-catching
              commercial ads and product shoots. We connect you with celebrities
              to amplify your brand and craft compelling digital campaigns,
              stunning designs, and unforgettable events. Whether it’s film
              production, social media marketing, or creative media services, we
              make your vision come alive with passion and flair. Let’s create
              something amazing together at Galiyaara!
            </p>
            {/* <h6
              data-aos="fade-right"
              className="font-[800] text-[24px] leading-[38.4px] text-[#FFFFFF]"
            >
              Let’s tell your story in the most captivating way possible!
            </h6> */}
          </div>
        </div>
      </div>
      <div
        className={`overflow-hidden sm:py-[30px] py-[14px] ${
          theme === "light" ? "bg-[#1C1C1C]" : "bg-[#E84037]"
        }`}
      >
        <div style={{ fontFamily: "GeneralSans" }}>
          <LineSlider />
          {/* <h6 className="font-[700] text-[36px] w-full leading-[44.64px] text-[#FDFBF9]">
            Film Production House
          </h6>
          <img src="assets/home/vector2.svg" alt="" />
          <h6 className="font-[700] text-[36px] w-full leading-[44.64px] text-[#FDFBF9]">
            Digital Media Services
          </h6>
          <img src="assets/home/vector2.svg" alt="" />
          <h6 className="font-[700] text-[36px] w-full leading-[44.64px] text-[#FDFBF9]">
            Advertising Agency
          </h6>
          <img src="assets/home/vector2.svg" alt="" />
          <h6 className="font-[700] text-[36px] w-full leading-[44.64px] text-[#FDFBF9]">
            Creative Services
          </h6>
          <img src="assets/home/vector2.svg" alt="" />
          <h6 className="font-[700] text-[36px] w-full leading-[44.64px] text-[#FDFBF9]">
            Film Production House
          </h6>
          <img src="assets/home/vector2.svg" alt="" />
          <h6 className="font-[700] text-[36px] w-full leading-[44.64px] text-[#FDFBF9]">
            Digital Media Services
          </h6>
          <img src="assets/home/vector2.svg" alt="" />
          <h6 className="font-[700] text-[36px] w-full leading-[44.64px] text-[#FDFBF9]">
            Advertising Agency
          </h6>
          <img src="assets/home/vector2.svg" alt="" />
          <h6 className="font-[700] text-[36px] w-full leading-[44.64px] text-[#FDFBF9]">
            Creative Services
          </h6>
          <img src="assets/home/vector2.svg" alt="" />
          <h6 className="font-[700] text-[36px] w-full leading-[44.64px] text-[#FDFBF9]">
            Film Production House
          </h6>
          <img src="assets/home/vector2.svg" alt="" />
          <h6 className="font-[700] text-[36px] w-full leading-[44.64px] text-[#FDFBF9]">
            Digital Media Services
          </h6>
          <img src="assets/home/vector2.svg" alt="" />
          <h6 className="font-[700] text-[36px] w-full leading-[44.64px] text-[#FDFBF9]">
            Advertising Agency
          </h6>
          <img src="assets/home/vector2.svg" alt="" />
          <h6 className="font-[700] text-[36px] w-full leading-[44.64px] text-[#FDFBF9]">
            Creative Services
          </h6>
          <img src="assets/home/vector2.svg" alt="" />
          <h6 className="font-[700] text-[36px] w-full leading-[44.64px] text-[#FDFBF9]">
            Film Production House
          </h6>
          <img src="assets/home/vector2.svg" alt="" />
          <h6 className="font-[700] text-[36px] w-full leading-[44.64px] text-[#FDFBF9]">
            Digital Media Services
          </h6>
          <img src="assets/home/vector2.svg" alt="" />
          <h6 className="font-[700] text-[36px] w-full leading-[44.64px] text-[#FDFBF9]">
            Advertising Agency
          </h6>
          <img src="assets/home/vector2.svg" alt="" />
          <h6 className="font-[700] text-[36px] w-full leading-[44.64px] text-[#FDFBF9]">
            Creative Services
          </h6>
          <img src="assets/home/vector2.svg" alt="" /> */}
        </div>
      </div>
      <div
        className={`sm:pt-[78px] overflow-hidden pt-[30px] ${
          theme === "light" ? "bg-[#FFFFFF]" : "bg-[#0e0e0e]"
        }`}
      >
        <div
          style={{ fontFamily: "GeneralSans" }}
          className="flex sm:px-[100px] px-[20px] sm:flex-row flex-col pb-[34px] border-b border-[#212121] justify-between sm:items-center items-start gap-[30px] sm:gap-0"
        >
          <div data-aos="fade-left">
            <h6
              className={`font-[600] sm:text-[40px] text-[20px] sm:leading-[48.76px] leading-[26px] ${
                theme === "light" ? "text-[#0e0e0e]" : "text-[#FFFFFF]"
              }`}
            >
              We create beautiful, <br /> practical works
            </h6>
          </div>
          <div data-aos="fade-right">
            <button
              onClick={() => navigate("/portfolios")}
              style={{ fontFamily: "GeneralSans" }}
              className={`hidden sm:flex border py-[4px] pr-[4px] pl-[24px] ${
                theme === "light"
                  ? "border-[#000000] text-[#000000]"
                  : "border-[#FFFFFF] text-[#FFFFFF]"
              } rounded-[30px] font-[600] flex items-center gap-[50px] text-[22px] leading-[35.2px]`}
            >
              View All
              <img src="assets/home/right-arrow.svg" alt="" />
            </button>
          </div>
        </div>
        <div className="sm:pt-[50px] pt-[30px] sm:pb-[135px] pb-[40px] overflow-hidden">
          <WeSlider />
        </div>
        <div
          className="flex  items-center justify-center mb-6 "
          data-aos="fade-right"
        >
          <button
            style={{ fontFamily: "GeneralSans" }}
            className={`block sm:hidden border py-[4px] pr-[4px] pl-[24px] ${
              theme === "light"
                ? "border-[#000000] text-[#000000]"
                : "border-[#FFFFFF] text-[#FFFFFF]"
            } rounded-[30px] font-[600] flex items-center gap-[50px] text-[22px] leading-[35.2px]`}
          >
            View All
            <img src="assets/home/right-arrow.svg" alt="" />
          </button>
        </div>
      </div>
      <div className="bg-[#E84037] overflow-hidden sm:pt-[66px] pt-[30px]">
        <div
          data-aos="fade-right"
          style={{ fontFamily: "GeneralSans" }}
          className="flex sm:flex-row flex-col pb-[30px] border-b border-[#FFFFFF] sm:items-center items-start sm:px-[100px] px-[20px]"
        >
          <h6 className="font-[700] text-[40px] leading-[48.76px] text-[#FFFFFF]">
            Galiyaara Services
          </h6>
          <div className="w-[61.77px] sm:block hidden h-[3px] rotate-[113.61deg] bg-[#FFFFFF]"></div>
          <p className="font-[500] text-[20px] leading-[32px] text-[#FFFFFF]">
            Our Areas of Experties
          </p>
        </div>
        <div className="py-[40px] border-b border-[#FFFFFF] sm:px-[100px] px-[20px]">
          <div className="flex flex-row justify-between w-full sm:items-center items-start sm:gap-0 gap-[14px]">
            <div data-aos="fade-right" style={{ fontFamily: "GeneralSans" }}>
              <Link
                to="/our-services#services"
                className="flex sm:items-center cursor-pointer items-start gap-[10px]"
              >
                <h6 className="font-[700] sm:text-[36px] text-[20px] sm:leading-[43.88px] leading-[26px] text-[#FFFFFF]">
                  01.
                </h6>
                <h6 className="font-[700]  sm:text-[36px] text-[20px] sm:leading-[43.88px] leading-[26px] text-[#FFFFFF]">
                  Film Production
                </h6>
              </Link>
            </div>
            <button data-aos="fade-left" onClick={() => setIsOpen("1")}>
              <img
                src="assets/home/white-right.svg"
                className={`transition-all duration-300 sm:h-auto h-[30px] ${
                  isOpen === "1" ? "rotate-[120deg]" : "rotate-[0deg]"
                }`}
                alt=""
              />
            </button>
          </div>
          <div data-aos="fade-down">
            <div
              style={{ fontFamily: "GeneralSans" }}
              className={`max-w-[723px] overflow-hidden transition-all duration-300 sm:pt-0 pt-[30px] w-full gap-[8px] flex items-center flex-wrap ${
                isOpen === "1" ? "sm:h-[50px] h-[144px]" : "h-0"
              }`}
            >
              <p className="font-[400] text-[16px] leading-[20.08px] text-[#FFFFFF]">
                Corporate Videos
              </p>
              <div className="h-[20px] w-[2px] bg-[#FFFFFF]"></div>
              <p className="font-[400] text-[16px] leading-[20.08px] text-[#FFFFFF]">
                Commercial AD Shoots
              </p>
              <div className="h-[20px] w-[2px] bg-[#FFFFFF]"></div>
              <p className="font-[400] text-[16px] leading-[20.08px] text-[#FFFFFF]">
                Documentaries
              </p>
              <div className="h-[20px] w-[2px] bg-[#FFFFFF]"></div>
              <p className="font-[400] text-[16px] leading-[20.08px] text-[#FFFFFF]">
                Testimonial Shoots
              </p>
              <div className="h-[20px] w-[2px] bg-[#FFFFFF]"></div>
              <p className="font-[400] text-[16px] leading-[20.08px] text-[#FFFFFF]">
                Product Shoot
              </p>
              <div className="h-[20px] w-[2px] bg-[#FFFFFF]"></div>
              <p className="font-[400] text-[16px] leading-[20.08px] text-[#FFFFFF]">
                Product Videos
              </p>
              <div className="h-[20px] w-[2px] bg-[#FFFFFF]"></div>
              <p className="font-[400] text-[16px] leading-[20.08px] text-[#FFFFFF]">
                Line Production
              </p>
              <div className="h-[20px] w-[2px] bg-[#FFFFFF]"></div>
              <p className="font-[400] text-[16px] leading-[20.08px] text-[#FFFFFF]">
                Conceptual Videos
              </p>
              <div className="h-[20px] w-[2px] bg-[#FFFFFF]"></div>
              <p className="font-[400] text-[16px] leading-[20.08px] text-[#FFFFFF]">
                Celebrity Endorsement
              </p>
            </div>
          </div>
        </div>

        <div className="flex flex-col py-[40px] border-b border-[#FFFFFF] items-start sm:px-[100px] px-[20px]">
          <div className="flex flex-row justify-between w-full sm:items-center items-start sm:gap-0 gap-[14px]">
            <div data-aos="fade-right" style={{ fontFamily: "GeneralSans" }}>
              <Link
                to="/our-services#services"
                className="flex sm:items-center cursor-pointer items-start gap-[10px]"
              >
                <h6 className="font-[700] sm:text-[36px] text-[20px] sm:leading-[43.88px] leading-[26px] text-[#FFFFFF]">
                  02.
                </h6>
                <h6 className="font-[700] sm:text-[36px] text-[20px] sm:leading-[43.88px] leading-[26px] text-[#FFFFFF]">
                  Social Media Marketing
                </h6>
              </Link>
            </div>
            <button data-aos="fade-left" onClick={() => setIsOpen("2")}>
              <img
                src="assets/home/white-right.svg"
                className={`transition-all duration-300 sm:h-auto h-[30px] ${
                  isOpen === "2" ? "rotate-[120deg]" : "rotate-[0deg]"
                }`}
                alt=""
              />
            </button>
          </div>
          <div data-aos="fade-down">
            <div
              style={{ fontFamily: "GeneralSans" }}
              className={`max-w-[723px] overflow-hidden transition-all duration-300 sm:pt-0 pt-[30px] w-full gap-[8px] flex items-center flex-wrap ${
                isOpen === "2" ? "sm:h-[50px] h-[108px]" : "h-0"
              }`}
            >
              <p className="font-[400] text-[16px] leading-[20.08px] text-[#FFFFFF]">
                Digital Campaign
              </p>
              <div className="h-[20px] w-[2px] bg-[#FFFFFF]"></div>
              <p className="font-[400] text-[16px] leading-[20.08px] text-[#FFFFFF]">
                SWOT Analysis
              </p>
              <div className="h-[20px] w-[2px] bg-[#FFFFFF]"></div>
              <p className="font-[400] text-[16px] leading-[20.08px] text-[#FFFFFF]">
                Posters
              </p>
              <div className="h-[20px] w-[2px] bg-[#FFFFFF]"></div>
              <p className="font-[400] text-[16px] leading-[20.08px] text-[#FFFFFF]">
                Motion Posters
              </p>
              <div className="h-[20px] w-[2px] bg-[#FFFFFF]"></div>
              <p className="font-[400] text-[16px] leading-[20.08px] text-[#FFFFFF]">
                Video Production
              </p>
              <div className="h-[20px] w-[2px] bg-[#FFFFFF]"></div>
              <p className="font-[400] text-[16px] leading-[20.08px] text-[#FFFFFF]">
                Event Management
              </p>
            </div>
          </div>
        </div>
        <div className="flex flex-col py-[40px] border-b border-[#FFFFFF] items-start sm:px-[100px] px-[20px]">
          <div className="flex flex-row justify-between w-full sm:items-center items-start sm:gap-0 gap-[14px]">
            <div data-aos="fade-right" style={{ fontFamily: "GeneralSans" }}>
              <Link
                to="/our-services#services"
                className="flex sm:items-center cursor-pointer items-start gap-[10px]"
              >
                <h6 className="font-[700] sm:text-[36px] text-[20px] sm:leading-[43.88px] leading-[26px] text-[#FFFFFF]">
                  03.
                </h6>
                <h6 className="font-[700] sm:text-[36px] text-[20px] sm:leading-[43.88px] leading-[26px] text-[#FFFFFF]">
                  Creative Media Agency
                </h6>
              </Link>
            </div>
            <button data-aos="fade-left" onClick={() => setIsOpen("3")}>
              <img
                src="assets/home/white-right.svg"
                className={`transition-all duration-300 sm:h-auto h-[30px] ${
                  isOpen === "3" ? "rotate-[120deg]" : "rotate-[0deg]"
                }`}
                alt=""
              />
            </button>
          </div>
          <div data-aos="fade-down">
            <div
              style={{ fontFamily: "GeneralSans" }}
              className={`max-w-[723px] overflow-hidden transition-all duration-300 sm:pt-0 pt-[30px] w-full gap-[8px] flex items-center flex-wrap ${
                isOpen === "3" ? "sm:h-[50px] h-[108px]" : "h-0"
              }`}
            >
              <p className="font-[400] text-[16px] leading-[20.08px] text-[#FFFFFF]">
                Designing & Development
              </p>
              <div className="h-[20px] w-[2px] bg-[#FFFFFF]"></div>
              <p className="font-[400] text-[16px] leading-[20.08px] text-[#FFFFFF]">
                Product Packaging
              </p>
              <div className="h-[20px] w-[2px] bg-[#FFFFFF]"></div>
              <p className="font-[400] text-[16px] leading-[20.08px] text-[#FFFFFF]">
                Shop Branding/In-Shop Branding
              </p>
              <div className="h-[20px] w-[2px] bg-[#FFFFFF]"></div>
              <p className="font-[400] text-[16px] leading-[20.08px] text-[#FFFFFF]">
                Brochure/Leaflet/Pamphlet
              </p>
              <div className="h-[20px] w-[2px] bg-[#FFFFFF]"></div>
              <p className="font-[400] text-[16px] leading-[20.08px] text-[#FFFFFF]">
                Many More
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="time-section overflow-hidden">
        <div className="time-box">
          <div className="grid sm:grid-cols-4 grid-cols-1 sm:gap-0 gap-[30px] pt-[200px]">
            <div
              data-aos="fade-up"
              style={{ fontFamily: "GeneralSans" }}
              className="flex flex-col items-center gap-[11px]"
            >
              <img src="assets/home/icon1.svg" alt="" />
              <h6 className="font-[700] text-[32px] leading-[39.68px] text-[#FFFFFF]">
                200+
              </h6>
              <p className="max-w-[157px] font-[400] text-[18px] leading-[22.59px] text-center text-[#FFFFFFCC]">
                Raised By Clients
              </p>
            </div>
            <div
              data-aos="fade-up"
              style={{ fontFamily: "GeneralSans" }}
              className="flex flex-col items-center gap-[11px]"
            >
              <img src="assets/home/icon2.svg" alt="" />
              <h6 className="font-[700] text-[32px] leading-[39.68px] text-[#FFFFFF]">
                10+
              </h6>
              <p className="max-w-[157px] font-[400] text-[18px] leading-[22.59px] text-center text-[#FFFFFFCC]">
                Years Of Experience
              </p>
            </div>
            <div
              data-aos="fade-up"
              style={{ fontFamily: "GeneralSans" }}
              className="flex flex-col items-center gap-[11px]"
            >
              <img src="assets/home/icon3.svg" alt="" />
              <h6 className="font-[700] text-[32px] leading-[39.68px] text-[#FFFFFF]">
                1000+
              </h6>
              <p className="max-w-[157px] font-[400] text-[18px] leading-[22.59px] text-center text-[#FFFFFFCC]">
                Project Delivery Done
              </p>
            </div>
            <div
              data-aos="fade-up"
              style={{ fontFamily: "GeneralSans" }}
              className="flex flex-col items-center gap-[11px]"
            >
              <img src="assets/home/icon4.svg" alt="" />
              <h6 className="font-[700] text-[32px] leading-[39.68px] text-[#FFFFFF]">
                4.8
              </h6>
              <p className="max-w-[157px] font-[400] text-[18px] leading-[22.59px] text-center text-[#FFFFFFCC]">
                Rating On Trust Pilot
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="bg-[#FFFFFF] overflow-hidden sm:pt-[78px] pt-[30px]">
        <div className="flex sm:px-[100px] px-[20px] sm:flex-row flex-col sm:gap-0 gap-[20px] pb-[34px] border-b border-[#212121] justify-between sm:items-center items-start">
          <h6
            style={{ fontFamily: "GeneralSans" }}
            data-aos="fade-right"
            className="font-[600] sm:text-[40px] text-[20px] sm:leading-[48.76px] leading-[26px]"
          >
            Our Prestigious Clients
          </h6>
          {/* <button
            data-aos="fade-left"
            style={{ fontFamily: "GeneralSans" }}
            className="border py-[4px] pr-[4px] pl-[24px] border-[#000000] rounded-[30px] font-[600] flex items-center gap-[50px] text-[22px] leading-[35.2px]"
          >
            View All
            <img src="assets/home/right-arrow.svg" alt="" />
          </button> */}
        </div>
        <div className="sm:px-[100px] px-[20px] sm:pb-[150px] pb-[50px] pt-[53px]">
          <div className="grid sm:grid-cols-5 items-center grid-cols-4 sm:gap-y-[20px] gap-y-[14px] sm:gap-[75px] gap-[30px]">
            <div data-aos="fade-up">
              <img src="assets/logo/1.jpg" alt="" />
            </div>
            <div data-aos="fade-down">
              <img src="assets/logo/2.png" alt="" />
            </div>
            <div data-aos="fade-up">
              <img src="assets/logo/3.png" alt="" />
            </div>
            <div data-aos="fade-down">
              <div className="flex justify-center">
                <img
                  src="assets/logo/4.png"
                  className="h-[30px] sm:h-[100px]"
                  alt=""
                />
              </div>
            </div>
            <div data-aos="fade-up">
              <div className="flex justify-center">
                <img
                  src="assets/logo/5.jpg"
                  className="h-[30px] sm:h-[100px]"
                  alt=""
                />
              </div>
            </div>
            <div data-aos="fade-down">
              <img src="assets/logo/6.png" alt="" />
            </div>
            <div data-aos="fade-up">
              <div className="flex justify-center">
                <img
                  src="assets/logo/7.png"
                  className="h-[30px] sm:h-[100px]"
                  alt=""
                />
              </div>
            </div>
            <div data-aos="fade-down">
              <img src="assets/logo/8.png" alt="" />
            </div>
            <div data-aos="fade-up">
              <img src="assets/logo/9.png" alt="" />
            </div>
            <div data-aos="fade-down">
              <div className="flex justify-center">
                <img
                  src="assets/logo/10.jpg"
                  className="h-[30px] sm:h-[100px]"
                  alt=""
                />
              </div>
            </div>
            <div data-aos="fade-up">
              <div className="flex justify-center">
                <img
                  src="assets/logo/11.jpg"
                  className="h-[30px] sm:h-[100px]"
                  alt=""
                />
              </div>
            </div>
            <div data-aos="fade-down">
              <div className="flex justify-center">
                <img
                  src="assets/logo/12.png"
                  className="h-[30px] sm:h-[100px]"
                  alt=""
                />
              </div>
            </div>
            <div data-aos="fade-up">
              <div className="flex justify-center">
                <img
                  src="assets/logo/13.JPG"
                  className="h-[30px] sm:h-[100px]"
                  alt=""
                />
              </div>
            </div>
            <div data-aos="fade-down">
              <div className="flex justify-center">
                <img
                  src="assets/logo/14.png"
                  className="h-[30px] sm:h-[100px]"
                  alt=""
                />
              </div>
            </div>
            <div data-aos="fade-up">
              <div className="flex justify-center">
                <img
                  src="assets/logo/15.jpg"
                  className="h-[30px] sm:h-[100px]"
                  alt=""
                />
              </div>
            </div>
            <div data-aos="fade-down">
              <div className="flex justify-center">
                <img
                  src="assets/logo/16.png"
                  className="h-[30px] sm:h-[100px]"
                  alt=""
                />
              </div>
            </div>
            <div data-aos="fade-up">
              <img src="assets/logo/17.png" alt="" />
            </div>
            <div data-aos="fade-down">
              <div className="flex justify-center">
                <img
                  src="assets/logo/19.png"
                  className="h-[30px] sm:h-[100px]"
                  alt=""
                />
              </div>
            </div>
            <div data-aos="fade-up">
              <img src="assets/logo/20.png" alt="" />
            </div>
            <div data-aos="fade-down">
              <img src="assets/logo/21.png" alt="" />
            </div>
          </div>
        </div>
        {/* <div className="px-[18px] sm:pb-[87px] pb-[40px]">
          <div className="bg-[#992119] rounded-[30px] relative sm:pb-[119px] pb-[40px] sm:pl-[88px] pl-[20px] sm:pr-[120px] pr-[20px]">
            <div className="sm:flex hidden gap-[8px] absolute left-[170px] -z-0">
              <img
                data-aos="fade-down"
                data-aos-delay="500"
                src="assets/home/wrap1.svg"
                alt=""
              />
              <img
                data-aos="fade-down"
                data-aos-duration="2500"
                data-aos-delay="500"
                src="assets/home/wrap1.svg"
                alt=""
              />
              <img
                data-aos="fade-down"
                data-aos-duration="3000"
                data-aos-delay="500"
                src="assets/home/wrap1.svg"
                alt=""
              />
            </div>
            <div className="flex sm:flex-row flex-col items-center sm:gap-[113px] gap-[20px]">
              <div className="relative w-full max-w-[282px] z-10 pt-[77px]">
                <img src="assets/home/icon5.svg" alt="" />
                <div className="bg-[#000000] absolute bottom-[-60px] w-full py-[14px] rounded-[30px] border-[2px] border-[#ffffff] max-w-[282px] flex flex-col items-center">
                  <h6
                    data-aos="fade-right"
                    className="font-[Mulish] font-[600] text-[22px] leading-[35.2px] text-[#FFFFFF]"
                  >
                    Sumit Dubey
                  </h6>
                  <p
                    data-aos="fade-left"
                    className="font-[Mulish] font-[600] text-[16px] leading-[25.6px] text-[#FFFFFFCC]"
                  >
                    - Founder, Galiyaara Films
                  </p>
                </div>
              </div>
              <p
                data-aos="fade-down"
                className="font-[Mulish] max-w-[802px] sm:pt-[123px] pt-[80px] w-full font-[500] text-[18px] leading-[28.8px] text-[#FFFFFF]"
              >
                Extremely Creative, Innovative, Energetic, Self Motivated person
                with more than 10 years of successful business leadership in
                film production . Creator of many Corporate Films, Television
                Commercial and Documentaries etc . He knows what works and has a
                fundamental understanding of film production. His talents
                identify and implement strong brand creative thought that match
                the aspirations of customer and clients . He is motivated as an
                artist, which means simply that he will deliver a concept that
                marks you out and accords with your true values. He helps you to
                implement your ideas and creative thoughts to make a beautifully
                created film, a corporate film, Ad Film or Documentary.
              </p>
            </div>
          </div>
        </div> */}
      </div>
      {/* <div
        className={`overflow-hidden ${
          theme === "light" ? "bg-[#F6F6F6]" : "bg-[#1c1c1c]"
        } sm:pt-[108px] pt-[40px]`}
      >
        <div data-aos="fade-up">
          <h6
            className={`font-[700] sm:px-[84px] px-[20px] sm:pb-[72px] pb-[40px] text-[20px] sm:text-[40px] leading-[26px] sm:leading-[49.6px] text-center ${
              theme === "light" ? "text-[#000000]" : "text-[#FFFFFF]"
            }`}
            style={{ fontFamily: "GeneralSans" }}
          >
            Our team include experienced Creative Director, Media Planners,
            Designers, Content Writers and Video Editors along with certified
            social media managers and we are committed to the success of our
            clients.
          </h6>
        </div>
        <div className="overflow-hidden sm:pb-[66px] pb-[40px]">
          <OurSlider />
        </div>
      </div> */}
      <div
        className={`pt-[48px] overflow-hidden sm:pb-[78px] pb-[40px] px-[18px] ${
          theme === "light" ? "bg-[#F6F6F6]" : "bg-[#0e0e0e]"
        }`}
      >
        <div className="bg-[#D42B1D] sm:pt-[76px] pt-[30px] sm:pb-[56px] pb-[30px] sm:px-[137px] px-[20px] rounded-[30px]">
          <div className="flex flex-col items-center">
            <button
              style={{ fontFamily: "GeneralSans" }}
              data-aos="fade-up"
              className="font-[400] text-[20px] leading-[24px] text-[#FFFFFF] border border-[#FFFFFF] py-[10px] px-[25px] rounded-[30px] mb-[8px]"
            >
              Tell us about it
            </button>
            <h6
              data-aos="fade-down"
              className="font-[500] sm:text-[48px] text-[30px] sm:pb-[73px] pb-[30px] text-center sm:leading-[60px] leading-[40px] text-[#FFFFFF]"
              style={{ fontFamily: "GeneralSans" }}
            >
              Have a great Idea!
            </h6>
          </div>
          <div className="grid sm:grid-cols-2 grid-cols-1 sm:gap-[75px] gap-[20px] sm:pb-[41px] pb-[20px]">
            <div data-aos="fade-right" className="flex flex-col">
              <p
                style={{ fontFamily: "GeneralSans" }}
                className="sm:pb-[17px] pb-0 font-[500] text-[20px] sm:leading-[60px] leading-[30px] text-[#FFFFFF]"
              >
                Your Name
              </p>
              <input
                type="text"
                className="sm:text-[20px] text-[16px] font-[Mulish] font-[400] leading-[24px] text-[#FFFFFFCC] placeholder:text-[#FFFFFFCC] bg-transparent sm:pb-[18px] pb-[8px] border-b-[2px] border-[#FFFFFF]"
                placeholder="Your Name"
              />
            </div>
            <div data-aos="fade-left" className="flex flex-col">
              <p
                style={{ fontFamily: "GeneralSans" }}
                className="sm:pb-[17px] pb-0 font-[500] text-[20px] sm:leading-[60px] leading-[30px] text-[#FFFFFF]"
              >
                Email
              </p>
              <input
                type="text"
                className="sm:text-[20px] text-[16px] font-[Mulish] font-[400] leading-[24px] text-[#FFFFFFCC] placeholder:text-[#FFFFFFCC] bg-transparent sm:pb-[18px] pb-[8px] border-b-[2px] border-[#FFFFFF]"
                placeholder="Email"
              />
            </div>
          </div>
          <div className="">
            <p
              data-aos="fade-right"
              style={{ fontFamily: "GeneralSans" }}
              className="pb-[7px] font-[500] text-[20px] sm:leading-[60px] leading-[30px] text-[#FFFFFF]"
            >
              I'm Interested in...
            </p>
            {/* multip select */}
            <div className="flex sm:flex-wrap flex-nowrap sm:flex-row flex-col sm:gap-y-[28px] gap-y-[8px] gap-[8px] sm:pb-[31px] pb-[16px]">
              {buttonData.map((button) => (
                <button
                  key={button}
                  //data-aos="fade-up"
                  style={{ fontFamily: "GeneralSans" }}
                  onClick={() => toggleButton(button)}
                  className={`font-[700] sm:text-[16px] text-[12px] sm:leading-[24px] leading-[18px] ${
                    selectedButtons.includes(button)
                      ? "bg-[#D42B1D] border-white text-white"
                      : "text-[#992119] bg-[#FFFFFF]"
                  } sm:py-[16px] py-[12px] sm:px-[25px] px-[18px] rounded-[30px] border border-[#FFFFFF] transition-all duration-300 hover:bg-[#FFFFFF] hover:text-[#212121]`}
                >
                  {button}
                </button>
              ))}
            </div>
            <p
              data-aos="fade-right"
              style={{ fontFamily: "GeneralSans" }}
              className="pb-[17px] font-[500] text-[20px] sm:leading-[60px] leading-[26px] text-[#FFFFFF]"
            >
              Tell Us More About Your Project
            </p>
            <div data-aos="fade-left" className="w-full sm:pb-[74px] pb-[30px]">
              <input
                type="text"
                className="sm:text-[20px] text-[14px] outline-none w-full font-[Mulish] font-[400] sm:leading-[24px] leading-[20px] text-[#FFFFFFCC] placeholder:text-[#FFFFFFCC] bg-transparent pb-[18px] border-b-[2px] border-[#FFFFFF]"
                placeholder="Something about your great idea"
              />
            </div>
            <div
              data-aos="fade-up"
              style={{ fontFamily: "GeneralSans" }}
              className="flex items-center justify-center"
            >
              <button className="border py-[4px] pr-[4px] pl-[24px] border-[#000000] rounded-[30px] font-[600] bg-[#FFFFFF] flex items-center gap-[25px] text-[22px] leading-[35.2px]">
                Submit
                <img src="assets/home/right-arrow.svg" alt="" />
              </button>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default Home;
