import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "aos/dist/aos.css";
import { ThemeProvider } from "./context/ThemeContext";
import TagManager from "react-gtm-module";

const root = ReactDOM.createRoot(document.getElementById("root"));
// const tagManagerArgs = {
//   gtmId: "G-0Q6Q5XWGWD",
// };

// TagManager.initialize(tagManagerArgs);
root.render(
  <React.StrictMode>
    <ThemeProvider>
      <App />
    </ThemeProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
